// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  WRAPPER_API: 'https://abbott-ph-api-dev.sttarter.com/api/v1',
  //AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
  // AUTH_BASE_URL: 'https://eu-auth-api.capillarytech.com',
  // API_BASE_URL: 'https://eu-api-gateway.capillarytech.com/mobile/v2',
  // API_BASE_URL: 'https://apac-apigateway.capillarytech.com/mobile/v2/api',
  // SALESFORCE_BASE_URL: ' https://abbott-ph-api-dev.sttarter.com/api/v1',

  // Proxy URL's
  AUTH_BASE_URL: 'https://eu-auth-api.capillarytech.com',
  //AUTH_BASE_URL: 'https://cap-eu-auth-api-com.sttarter.com',
  API_BASE_URL: 'https://cap-eu-api-gw-com.sttarter.com/mobile/v2/api',
  IMAGE_GET: 'https://eu-api-gateway.capillarytech.com/mobile/v2/api',

  SALESFORCE_BASE_URL: ' https://abbott-ph-api-dev.sttarter.com/api/v1',
  API_URl_TRACEBILITY: 'https://abbott-ph-api-dev.sttarter.com/api',
  IMAGE_UPLOAD: 'https://apac-apigateway.capillarytech.com/mobile/v2/',
  EMAIL_CHECK_API: 'https://abbott-ph-api-dev.sttarter.com/api/v1',
  CMS_URL:
    'https://api.sttarter.com/contentsystem/c2ff7690d4a451d462c01744dafa17d2',

  STTARTER_GAMEPLAY_REWARD:
    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb',
  STTARTER_GAMEPLAY_FIRSTTIME_SCAN:
    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb/firsttimescanuat',
  STTARTER_GAMEPLAY_LUCKY_DRAW:
    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb/luckydrawuat',

  //https://eu.api.capillarytech.com

  // GAME_MAP_API: "https://cap-intouch-in.sttarter.com",
  GAME_MAP_API: 'https://cap-apac-api-com.sttarter.com/v1.1',

  NUTRITION_CUST_ADD_URL: 'https://cap-intouch-in.sttarter.com',
  NUTRITION_TILL_ID: 'nutrition',
  //OAUTH_TOKEN:'https://cap-api-eu.sttarter.com/v3',
  OAUTH_TOKEN: 'https://cap-apac-api-com.sttarter.com/v3',
  SOCIAL_LOGIN_URL: 'https://cap-s3-apac.sttarter.com',

  LICENSEKEY:
    'AeliUWHLQ/QqARqoEztBkSsu6zcXPYf4NmfSmgEq4xPTHb86hHMuK8BeXfqhVjaGN03m9CQ1bXE1d8Ct+XqFfDYrDcAGasxUu0BA8m94aWMrS9I0jHUrQzlznswsJ4M2GR10iq8Y7xQHJrcKwQWDOoZDsCyLXmk0fH3knZV2NW8TXPCJWHh7P84hZ5HtU4h8O29tkw17qLOgR33YqWnXgUljxDEVRV06S0hB0Nds+SWtYi6RcmSOH5cKppjNZnZKDw/J4TxupFZkVSy7C3qfUXd3CZWvKdqX9gDw/apeHs+ySz0n6gjh8Qp4PTJVXtfV83bdD+B6RZuQXoCVUAlXfMYPXi8qe9CgcEdd/4UKUjmrWt244350R15LuuR/elmSaHuWKfoJeOBoTgk9yUtZ10VXn1biemkDZEz5sD1H7Y7/c1ls0yi4pdh9Ul0Ed76nCSjbz2gEWZafMPCIbQ3le/U9qJCFZ/qxJG2b5r9ZNJqOpN86STxouHrB4R/WEaqYHLhV63sdVoEpgYjdGLjtZ1TnK+ZcVKdjMrM/XOzRmac6MrtKmUXMMPnRfSb779XdSM0j0sZVVe4qvDdR1ZN4mpQVHiVWmZ2vQVMD9aQdcCSOfSVy8DuE4ALUBjYAXdo/huJkOJbXOOKOiYqKJjCFkxqXiEQ57n/+lWK2rxt28o3VAPxAB78fbVOcafmtgSHLHkdUYaOJX1Ead9Pg0seg5Erxg3APysKSpBwsW2dI01Us7dY5nzaQ2U3/v+eFuPExxzQbbPbiNapwQhaCpaWqARtJQzH23nU5ojoRoBmdtEIBGDoVXbkc2nXzIMnanfj7jDuxHBH4GCE3OUROtrgKflnHHdQbQn7R67Fi+Kv89ZKu8PFzJt17FvX4GeuUKuCPonGGeO8O2Xu3D0OUp8/UMZcA0b7Pe75UQZ7Q7x/lvpk7JxyWV7F4W+Yos38kjp7dR250XJGnNF67H6t76yNxHMQg8sXNoY6w8u908hKsfrzpXeGumfRitqTf8mZibAMRvxE4YrpATqUZE27Lf3K/ojuHXhS+tFrVcKNATb5+qg2kgGoJLpCXxGKmq4r2fd2lwcaQi1DUv0WJl+tv980NcDt3hfkyV2iKfg429aKrEsiXJxGTAnUseOCWxxKlh4X1KidaIXttXpQhT5ffPm/07A==',
  BRAND: 'ABBOTTKUWAITUAT',
  DEVICE_ID: '1234551231',
  //DEVICE_ID: '12345',
  IDENTIFIER_TYPE: 'MOBILE',
  //TILL_ID: 'abbott.sg.demo.admin',
  TILL_ID: 'abbott.kuwait',
  COUNTRY_CODE: 'KW',
  LANGUAGE: 'en_US',
  MAXMOBNUM: 8,
  MINMOBNUM: 8,
  rewarddetail: 'details',
  ORG_ID: '100627',
  bugsnag_version: '04072023',
  MOBILE_APP_KEY: '85a4447a3b438fbb6e33e5c0428460d3',
};

// https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb/skupoints

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error'; // Included with Angular CLI.
