<app-header title="Validate Receipt" *ngIf="webcamImage"></app-header>
<!-- header bg color changes when the title is Validate Receipt -->

<div class="pageHeader-blue-bg row mx-0 px30" *ngIf="!webcamImage">
  <div
    class="col-12 justify-content-left d-flex position-relative header-height align-items-center d-flex"
  >
    <a (click)="onClickBack()">
      <i class="fa fa-angle-left"></i>
    </a>
    <h1 class="px-2">Back</h1>
  </div>
</div>

<div class="cameraContainer globalContainer">
  <div class="videoCam mt-4">
    <div class="snapshot" *ngIf="webcamImage">
      <img [src]="url" style="height: 420px" />
      <div class="confirmButtons mt-4 row">
        <div class="col-6 pr-0">
          <button class="actionBtn w-100 btn" (click)="retake()">Cancel</button>
        </div>
        <div class="col-6">
          <button (click)="uploadImage()" class="btn w-100 btnValidate">
            Validate
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="!webcamImage">
      <webcam
        [height]="500"
        [width]="600"
        [trigger]="triggerObservable"
        (imageCapture)="handleImage($event)"
        *ngIf="showWebcam"
        [allowCameraSwitch]="allowCameraSwitch"
        [switchCamera]="nextWebcamObservable"
        [imageQuality]="1"
        [mirrorImage]="never"
        (initError)="handleInitError($event)"
      >
      </webcam>
    </div>
  </div>
  <div class="camButtons row mt-4 mx-0 mb-4" *ngIf="!webcamImage">
    <div class="uploadBtn col-2">
      <input
        id="upload"
        type="file"
        name="profile"
        accept="image/*"
        (change)="fileChangeEvent($event)"
      />
      <img class="" src="assets/images/uploadfile.png" />
    </div>
    <div class="captureBtn col-8 text-center">
      <img
        class=""
        src="assets/images/Union 12.svg"
        (click)="triggerSnapshot()"
      />
    </div>
    <!-- <div class="col-2">
      <img class="" src="../../../../assets/images/flashnew.png" />
    </div> -->
  </div>
</div>

<h4 *ngIf="errors.length > 0">Messages:</h4>
<ul *ngFor="let error of errors">
  <li>{{ error | json }}</li>
</ul>
