<app-header title="PediaSure<sup>®</sup>   range"></app-header>
<section class="globalContainer bg-lightBlue">
  <div class="product-detail-page px15">
    <div class="bannner-section">
      <div class="imgSection">
        <img
          src="assets/images/products_img/latest_products/pediasure3-vainilla-400-kw.png"
        />
      </div>
      <div class="titleSection v-top">
        <h2 class="fs20 color-yellow mb-0">
          PediaSure Complete 3+ Vanilla, 400g
        </h2>
      </div>
    </div>
    <div class="product-detail-container bg-white btl20 btr20 pt-4 pb70">
      <div class="content-card">
        <h4 class="fs20 font-darkblue mt30 mb-3">
          Balanced Nutrition to Help Kids Grow
        </h4>
        <p class="mb-3">
         
            <strong>PediaSure</strong> is the first complementary food scientifically designed to meet the 
            essential nutrition needs of the growing child to promote growth. <strong>PediaSure</strong>, now 
            with added arginine and natural vitamin K<sub>2</sub>, provides all essential nutrients needed 
            for healthy growth. Supported by more than 25 years of clinical evidence.
        </p>
        <div class="pdpimgs">
          <figure>
            <img
              src="assets/images/pdp/PediaSureComplete-3-Plus-Vanilla-400g-A.png"
              alt="pdp"
            />
          </figure>
          <figure>
            <img
              src="assets/images/pdp/PediaSureComplete-3-Plus-Vanilla-400g-B.png"
              alt="pdp"
            />
          </figure>
        </div>
      </div>
    </div>
  </div>
  <div class="arrow-up-fixed">
    <a (click)="onArrowUp()">
      <i class="fa fa-arrow-up"></i>
    </a>
  </div>
</section>
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
