<app-header title="PediaSure<sup>®</sup>   range"></app-header>
<section class="globalContainer px15 py-5 bg-lighBlue pb70">
  <div class="productListContainer px15 pb70">
    <h4 class="fs20 font-darkblue mb-3">Check out our products</h4>
    <div class="list-content">
      <p>
        From never missing a growth moment as toddler or child, to fueling final
        growth spurt during adolescence, PediaSure<sup>®</sup> gets the science
        of growth on your side.
      </p>
    </div>
    <div class="product-card">
      <div class="row">
        <div class="col-4">
          <img
            class="max-100"
            src="assets//images/products_img/latest_products/kw_pediasure1_400_Vanilla2.png"
          />
        </div>
        <div class="col-8">
          <h4 class="fs16 font-darkblue">PediaSure 1+ Vanilla, 400g</h4>
          <!-- <span class="agePreference">1-10 years old</span> -->
          <p>
            PediaSure Vanilla is a balanced nutrition developed in 1988 as the
            first dietary supplement designed to meet the essential nutritional
            needs of the growing child.
          </p>
          <a href="#" [routerLink]="['/pediasure-vannila']">LEARN MORE</a>
        </div>
      </div>
    </div>

    <div class="product-card">
      <div class="row">
        <div class="col-4">
          <img
            class="max-100"
            src="assets//images/products_img/latest_products/kw_pediasure1_900_Vanilla.png"
          />
        </div>
        <div class="col-8">
          <h4 class="fs16 font-darkblue">PediaSure 1+ Vanilla, 900g</h4>
          <!-- <span class="agePreference">1-10 years old</span> -->
          <p>
            PediaSure Vanilla is a balanced nutrition developed in 1988 as the
            first dietary supplement designed to meet the essential nutritional
            needs of the growing child.
          </p>
          <a href="#" [routerLink]="['/pediasure-one-vannila-nine']">LEARN MORE</a>
        </div>
      </div>
    </div>

    <div class="product-card">
      <div class="row">
        <div class="col-4">
          <img
            class="max-100"
            src="assets//images/products_img/latest_products/kw_pediasure1_1600_Vanilla.png"
          />
        </div>
        <div class="col-8">
          <h4 class="fs16 font-darkblue">PediaSure 1+ Vanilla, 1600g</h4>
          <!-- <span class="agePreference">10-15 years old</span> -->
          <p>
            PediaSure Vanilla is a balanced nutrition developed in 1988 as the
            first dietary supplement designed to meet the essential nutritional
            needs of the growing child.
          </p>
          <a href="#" [routerLink]="['/pediasure-one-vannila-sixteen']">LEARN MORE</a>
        </div>
      </div>
    </div>

    <div class="product-card">
      <div class="row">
        <div class="col-4">
          <img
            class="max-100"
            src="assets//images//products_img/latest_products/kw_pediasure1_400_Chocolate2.png"
          />
        </div>
        <div class="col-8">
          <h4 class="fs16 font-darkblue">PediaSure 1+ Chocolate 400g</h4>
          <!-- <span class="agePreference">1-10 years old</span> -->
          <p>
            PediaSure Chocolate is a balanced nutrition developed in 1988 as the
            first dietary supplement designed to meet the essential nutritional
            needs of the growing child.
          </p>
          <a href="#" [routerLink]="['/pediasure-one-chocolate-four']">LEARN MORE</a>
        </div>
      </div>
    </div>

    <div class="product-card">
      <div class="row">
        <div class="col-4">
          <img
            class="max-100"
            src="assets//images//products_img/latest_products/kw_pediasure1_900_Chocolate.png"
          />
        </div>
        <div class="col-8">
          <h4 class="fs16 font-darkblue">PediaSure 1+ Chocolate 900g</h4>
          <!-- <span class="agePreference">1-10 years old</span> -->
          <p>
            PediaSure Chocolate is a balanced nutrition developed in 1988 as the
            first dietary supplement designed to meet the essential nutritional
            needs of the growing child.
          </p>
          <a href="#" [routerLink]="['/pediasure-one-chocolate-nine']">LEARN MORE</a>
        </div>
      </div>
    </div>

    <div class="product-card">
      <div class="row">
        <div class="col-4">
          <img
            class="max-100"
            src="assets//images//products_img/latest_products/pediasure3-vainilla-400-kw.png"
          />
        </div>
        <div class="col-8">
          <h4 class="fs16 font-darkblue">PediaSure 3+ Vanilla, 400g</h4>
          <!-- <span class="agePreference">1-10 years old</span> -->
          <p>
            PediaSure Vanilla is a balanced nutrition developed in 1988 as the
            first dietary supplement designed to meet the essential nutritional
            needs of the growing child.
          </p>
          <a href="#" [routerLink]="['/pediasure-three-vanilla-four']">LEARN MORE</a>
        </div>
      </div>
    </div>

    <div class="product-card">
      <div class="row">
        <div class="col-4">
          <img
            class="max-100"
            src="assets//images//products_img/latest_products/pediasure3-vainilla-900-kw.png"
          />
        </div>
        <div class="col-8">
          <h4 class="fs16 font-darkblue">PediaSure 3+ Vanilla 900g</h4>
          <!-- <span class="agePreference">1-10 years old</span> -->
          <p>
            PediaSure Vanilla is a balanced nutrition developed in 1988 as the
            first dietary supplement designed to meet the essential nutritional
            needs of the growing child.
          </p>
          <a href="#" [routerLink]="['/pediasure-three-vanilla-nine']">LEARN MORE</a>
        </div>
      </div>
    </div>

    <div class="product-card">
      <div class="row">
        <div class="col-4">
          <img
            class="max-100"
            src="assets//images//products_img/latest_products/pediasure3-chocolate-400-kw.png"
          />
        </div>
        <div class="col-8">
          <h4 class="fs16 font-darkblue">PediaSure 3+ Chocolate 400g</h4>
          <!-- <span class="agePreference">1-10 years old</span> -->
          <p>
            PediaSure Chocolate is a balanced nutrition developed in 1988 as the
            first dietary supplement designed to meet the essential nutritional
            needs of the growing child.
          </p>
          <a href="#" [routerLink]="['/pediasure-three-chocolate-four']">LEARN MORE</a>
        </div>
      </div>
    </div>

    <div class="product-card">
      <div class="row">
        <div class="col-4">
          <img
            class="max-100"
            src="assets//images//products_img/latest_products/pediasure3-chocolate-900-kw.png"
          />
        </div>
        <div class="col-8">
          <h4 class="fs16 font-darkblue">PediaSure 3+ Chocolate 900g</h4>
          <!-- <span class="agePreference">1-10 years old</span> -->
          <p>
            PediaSure Chocolate is a balanced nutrition developed in 1988 as the
            first dietary supplement designed to meet the essential nutritional
            needs of the growing child.
          </p>
          <a href="#" [routerLink]="['/pediasure-three-chocolate-nine']">LEARN MORE</a>
        </div>
      </div>
    </div>
  </div>
</section>
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
