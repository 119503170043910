<app-header title="Log in"></app-header>

<section class="login-container mt30 px30 pb70">
  <div class="text-center">
    <h4 class="mb-2 fs20 text-center">Log in to your account</h4>
    <span class="fs16 text-center">Enter your password to continue</span>
  </div>

  <form
    [formGroup]="loginForm"
    class="login-Form mt40"
    (ngSubmit)="onSubmitLoginForm()"
  >
    <div class="position-relative">
      <div class="form-group mt20">
        <label>Mobile number</label>
        <input
          type="number"
          formControlName="mobileNumber"
          class="form-control mobNumber"
          placeholder="9xx-xxxx-xxxx"
          [readOnly]="isParamMobileNumber"
          [ngClass]="{
            'is-invalid': submittedLoginForm && loginFormVal.mobileNumber.errors
          }"
        />
        <span class="mobPlaceholder d-flex align-items-center"
          >
          <!-- <img src="../../../assets/images/flag.png" /> -->
          +965
          <img src="assets/images/down-arrow.png" />
        </span>
        <img
          *ngIf="loginFormVal.mobileNumber.status == 'VALID'"
          class="correct-icon"
          src="assets/images/Group 4868.svg"
        />
      </div>
    </div>

    <div
      *ngIf="submittedLoginForm && loginFormVal.mobileNumber.errors"
      class="error"
    >
      <span *ngIf="loginFormVal.mobileNumber.errors.required"
        >Please enter mobile number</span
      >
      <span *ngIf="loginFormVal.mobileNumber.errors.pattern"
        >Invalid number</span
      >
    </div>

    <div class="position-relative mt20">
      <div class="form-group">
        <label>Password</label>
        <input
          [type]="showPasswordText ? 'text' : 'password'"
          formControlName="password"
          class="form-control position-relative"
          placeholder="Enter your password"
          [ngClass]="{
            'is-invalid': submittedLoginForm && loginFormVal.password.errors
          }"
        />
        <!-- <img class="mobVerified" src="../../../assets/images/eye-closed.png" /> -->
        <img
          class="eye-icon"
          *ngIf="showEyeCloseIcon"
          src="assets/images/Group 5035.svg"
          (click)="onClickEyeCloseIcon()"
        />
        <img
          class="eye-icon-open"
          *ngIf="showEyeOpenIcon"
          src="assets/images/visibility.png"
          (click)="onClickEyeOpenIcon()"
        />
      </div>
    </div>

    <div *ngIf="submittedLoginForm && !invalidPassword" class="error">
      <span *ngIf="loginFormVal.password?.errors?.required"
        >Please enter password</span
      >
      <span *ngIf="loginFormVal.password?.errors?.pattern"
        >Invalid password</span
      >
    </div>

    <div *ngIf="submittedLoginForm" class="error">
      <span *ngIf="invalidPassword">Entered password is invalid</span>
    </div>

    <div class="checkbox m-2">
      <input
        type="checkbox"
        class="orm-check-input mr-2 position-relative ml-0"
        value=""
        formControlName="saveMyPassword"
      />
      <label>Save my password</label>
    </div>

    <div class="text-center mt40">
      <button class="btn btn-primary me-2" type="submit">Log in</button>
    </div>
  </form>
  <div class="text-center mt12">
    <a
      [routerLink]="['/forgot-password']"
      class="font-blue text-no-underline fs16"
      >Forgot password?</a
    >
  </div>

  <div class="text-center mt50">
    <span class="fs16">
      Don’t have an account?
      <a [routerLink]="['/login-signup']" class="font-blue text-no-underline"
        >Sign up now!</a
      ></span
    >
  </div>
</section>
