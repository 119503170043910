import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from '../../../services/bugsnag.service';

@Component({
  selector: 'app-editaccount',
  templateUrl: './editaccount.component.html',
  styleUrls: ['./editaccount.component.scss']
})
export class EditaccountComponent implements OnInit {
  editAccountForm: FormGroup;
  submittedAccountForm: boolean = false;
  editForm: {
   
  };
  customerData: any;
  customerName: string;
  loyaltyPoints: any;
  emailAlreadyExists: boolean = false;
  maxDate: any;
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private bsService: BugsnagService,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.maxDate = moment();
    this.maxDate = new Date(this.maxDate)
    this.editAccountForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      email: ['', [ Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      childName:[''],
      childDOB:['',[Validators.required,this.ValidateDOB.bind(this)]],
    })
    this.getCustomer();

  }

  ValidateDOB(control: AbstractControl): { [key: string]: any } | null {
    let birthday = moment(control.value, "DD/MM/YYYY")
     var age = moment().diff(moment(birthday),'years')
     var months = moment().diff(moment(birthday),'months')
     console.log("month: ",months)
     if(months <=6){
       return { 'maxAgeIsSixMonths': true };
     }
    else if (age > 13) {
       return { 'maxAgeIs13': true };
     }
     return null;
   }
 

  get editAccountFormVal() {
    return this.editAccountForm.controls;
  }

  onSubmitEditAccountForm() {
    this.submittedAccountForm = true;
    if (this.editAccountForm.invalid) {
      return;
    } else {
      let userData = this.editAccountForm.value;
      console.log("userData: ", userData);
      let filledFormValues = this.getuserForm(userData);
      let getMobile = sessionStorage.getItem('mobileNo');
      let token = sessionStorage.getItem('authToken');
      this.spinner.show();
      if (
        this.customerData?.email !==
        filledFormValues?.root?.customer[0]?.email &&
        filledFormValues?.root?.customer[0]?.email !== ''
      ) {
        this.spinner.show();
        this.apiService
          .checkExistingEmail(userData.email)
          .pipe(first()).subscribe((res: any) => {
            this.spinner.hide();

            if (res.result.statusCode == 200) {
              this.emailAlreadyExists = false
              if (res.result.message === 'User not found') {
                let updateEmail = {
                  root: {
                    customer: [
                      {
                        identifier: 'email',
                        old_value: this.customerData?.email,
                        new_value: userData?.email,
                      },
                    ],
                  },
                };
                this.apiService
                  .updateCapCustomerIdentity(updateEmail, getMobile, token)
                  .pipe(first())
                  .subscribe(
                    (res) => {
                      console.log(res['customers'].customer.new_value)
                      this.editAccountForm.patchValue({
                        email: res['customers'].customer.new_value
                      })
                      console.log(res, '=== updated email ===');
                      this.spinner.hide();
                    },
                    (err) => {
                      this.bsService.notifyError(err);
                      console.log(err, 'err===');
                      this.spinner.hide()
                    }
                  );
              }
            } else if (res.result.statusCode == 420) {
              this.emailAlreadyExists = true;
            }
          }, err => {
            this.bsService.notifyError(err);
            this.emailAlreadyExists = true;
            console.log(err)
            return
          })
      }

      let myuuid = uuidv4();
      let loggerFormData = {
        unique_id: myuuid,
        message: JSON.stringify(filledFormValues),
      };
      console.log(loggerFormData, 'loggerAPI');
      this.apiService.loggerApi(loggerFormData).subscribe((loggerData) => {
        console.log(loggerFormData, 'loggerAPI');
      });
      this.spinner.show();
      this.apiService
        .updateCapCustomer(filledFormValues, getMobile, token)
        .pipe(first())
        .subscribe(
          (res) => {
            let myuuid = uuidv4();
            let loggerFormData = {
              unique_id: myuuid,
              message: JSON.stringify(res),
            };
            this.apiService
              .loggerApi(loggerFormData)
              .subscribe((loggerData) => {
                console.log(loggerData, 'loggerAPI');
                window.location.reload();
              });
            this.spinner.hide();
            if (res['status'].code == 200) {
              console.log('profile updated')
              // this.apiService.showToaster('Profil berhasil diperbarui')
              // this.profileUpdate = true;
              // setTimeout(() => {
              //   this.profileUpdate = false;
              // }, 5000)
            }
          }, err => {
            this.bsService.notifyError(err);
            this.spinner.hide();
            console.log(err)
          }
        )
    }
  }

  customerNamePresent: any = false;

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    if (!mobile || !token) {
      console.log("customer not loggged in")
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe(res => {
        if (res['status'].code == 200) {
          this.spinner.hide();
          this.customerData = res['customers']['customer'][0];
          console.log(this.customerData);
          let childName;
          let childDOB;
          this.customerData.custom_fields.field.forEach((field => {
            if(field.name == 'child_name') childName = field['value']
            if(field.name == 'child_dob') childDOB = field['value']

          }))
          this.customerName = this.customerData.firstname
          this.customerNamePresent = true;
          this.loyaltyPoints = this.customerData.loyalty_points
          console.log(this.loyaltyPoints)
          this.editAccountForm.patchValue({
            fullName: this.customerName,
            email: this.customerData?.email,
            childName: childName,
            childDOB: moment(childDOB).format('DD/MM/YYYY')
          })
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;

        }

      }), err => {
        this.bsService.notifyError(err);
        this.spinner.hide();
        console.log(err)
      }
    }

  }

  getuserForm(userData: any) {
    return (this.editForm = {
      root: {
        customer: [
          {
            name: userData.fullName,
            email: userData?.email,
            custom_fields: {
              field: [
                {
                  name: 'child_name',
                  value: userData.childName,
                },
                {
                  name: 'child_dob',
                  value: moment(userData.childDOB, "DD-MM-YYYY").format("YYYY-MM-DD"),
                },
              ]}
          },
        ],
      },
    });
  }


}
