import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/service/api.service';
import { BugsnagService } from '../../services/bugsnag.service';

@Component({
  selector: 'app-pediasure-three-chocolate-four',
  templateUrl: './pediasure-three-chocolate-four.component.html',
  styleUrls: ['./pediasure-three-chocolate-four.component.scss'],
})
export class PediasureThreeChocolateFourComponent implements OnInit {
  customerData: any;
  customerName: string;
  loyaltyPoints: any;
  customerNamePresent: any = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) {}

  ngOnInit(): void {
    this.getCustomer();
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    if (!mobile || !token) {
      console.log('customer not loggged in');
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
        if (res['status'].code == 200) {
          this.spinner.hide();
          this.customerData = res['customers']['customer'][0];
          console.log(this.customerData);
          this.customerName =
            this.customerData.firstname + ' ' + this.customerData.lastname;
          this.customerNamePresent = true;
          this.loyaltyPoints = this.customerData.loyalty_points;
          console.log(this.loyaltyPoints);
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      }),
        (err) => {
          this.bsService.notifyError(err);
          this.spinner.hide();
          console.log(err);
        };
    }
  }

  onArrowUp() {
    window.scrollTo({ top: 0 });
  }
}
