import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { first } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import { BugsnagService } from '../services/bugsnag.service';
declare var jsQR:any;
declare var ZXing:any;

@Component({
  selector: 'app-test-qr',
  templateUrl: './test-qr.component.html',
  styleUrls: ['./test-qr.component.scss']
})
export class TestQrComponent implements OnInit {

  constructor(private router: Router, private apiService: ApiService, private spinner: NgxSpinnerService, private APIService: ApiService, private route: ActivatedRoute, private location: Location,private bsService: BugsnagService) {
     window.scrollTo({top: 0})
    // this.productCode = this.route.snapshot.params.data;
    // console.log(this.productCode, 'productCode====')
  }

  public ngOnInit(): void {

  //   var video = document.createElement("video");
  //   var canvasElement = document.getElementById("canvas")  as HTMLCanvasElement;
  //   var canvas = canvasElement.getContext("2d");
  //   var loadingMessage = document.getElementById("loadingMessage");
  //   var outputContainer = document.getElementById("output");
  //   var outputMessage = document.getElementById("outputMessage");
  //   var outputData = document.getElementById("outputData");

  //   function drawLine(begin, end, color) {
  //     canvas.beginPath();
  //     canvas.moveTo(begin.x, begin.y);
  //     canvas.lineTo(end.x, end.y);
  //     canvas.lineWidth = 4;
  //     canvas.strokeStyle = color;
  //     canvas.stroke();
  //   }

  //   // Use facingMode: environment to attemt to get the front camera on phones
  //   navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function(stream) {
  //     video.srcObject = stream;
  //     video.setAttribute("playsinline", 'true'); // required to tell iOS safari we don't want fullscreen
  //     video.play();
  //     requestAnimationFrame(tick);
  //   });

  //   function tick() {
  //     loadingMessage.innerText = "⌛ Loading video..."
  //     if (video.readyState === video.HAVE_ENOUGH_DATA) {
  //       loadingMessage.hidden = true;
  //       canvasElement.hidden = false;
  //       outputContainer.hidden = false;

  //       canvasElement.height = video.videoHeight;
  //       canvasElement.width = video.videoWidth;
  //       canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
  //       var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
  //       var code = jsQR(imageData.data, imageData.width, imageData.height, {
  //         inversionAttempts: "dontInvert",
  //       });
  //       if (code) {
  //         drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
  //         drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
  //         drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
  //         drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
  //         outputMessage.hidden = true;
  //         outputData.parentElement.hidden = false;
  //         outputData.innerText = code.data;
  //       } else {
  //         outputMessage.hidden = false;
  //         outputData.parentElement.hidden = true;
  //       }
  //     }
  //     requestAnimationFrame(tick);
  //   }


  //window.addEventListener('load', function () {
    let selectedDeviceId;
    const codeReader = new ZXing.BrowserMultiFormatReader()
    console.log('ZXing code reader initialized')
    codeReader.listVideoInputDevices()
      .then((videoInputDevices) => {
        const sourceSelect = document.getElementById('sourceSelect')
        selectedDeviceId = videoInputDevices[0].deviceId
        // if (videoInputDevices.length >= 1) {
        //   videoInputDevices.forEach((element) => {
        //     const sourceOption = document.createElement('option')
        //     sourceOption.text = element.label
        //     sourceOption.value = element.deviceId
        //     sourceSelect.appendChild(sourceOption)
        //   })

        //   sourceSelect.onchange = () => {
        //     selectedDeviceId = sourceSelect.value;
        //     console.log(selectedDeviceId, 'selectedDeviceId')
        //   };

        //   const sourceSelectPanel = document.getElementById('sourceSelectPanel')
        //   sourceSelectPanel.style.display = 'block'
        // }

        //document.getElementById('startButton').addEventListener('click', () => {
          codeReader.decodeFromVideoDevice(undefined, 'video', (result, err) => {
            if (result) {
              console.log(result)
              alert(result.text)
              document.getElementById('result').textContent = result.text
            }
            if (err && !(err instanceof ZXing.NotFoundException)) {
              this.bsService.notifyError(err);
              console.error(err)
              document.getElementById('result').textContent = err
            }
          })
          console.log(`Started continous decode from camera with id ${selectedDeviceId}`)
       // })

        // document.getElementById('resetButton').addEventListener('click', () => {
        //   codeReader.reset()
        //   document.getElementById('result').textContent = '';
        //   console.log('Reset.')
        // })

      })
      .catch((err) => {
        this.bsService.notifyError(err);
        console.error(err)
      })
  //})

  }

}
