<section class="globalContainer pt-5 bg-Blue">
  <div class="welcome-container">
    <div class="text-center d-flex justify-content-center align-items-center">
      <div class="reward-header">
        <img src="assets/images/Sure_Loyalty_Welcome.png" alt="Rewards" />
      </div>
    </div>
    <div class="welcome-card text-center mt-5">
      <img src="assets/images/pediasure.png" alt="PediaSure" />
      <p class="font-darkblue fs24 mt-4 mb-1">Thank you for your purchase!</p>
      <p>
        Learn about PediaSure<sup>®</sup>
        <a href="#" class="font-blue">here</a>.
      </p>

      <div class="btnSection mt-5">
        <p>
          To start earning points, log in to your <br /><span
            class="font-darkblue"
            >Sure Abbott Rewards Program</span
          >
          account.
        </p>

        <button
          type="submit"
          class="primaryBtn Btn mt-2"
          (click)="onClickLoginOrAcc()"
        >
          Create account / Log in
        </button>
        <button
          type="submit"
          class="primaryBtn mt-2 btn-white Btn"
          routerLink="/about/family"
        >
          Learn about Sure Abbott Rewards Program
        </button>
      </div>
      <p class="terms-text fs13 my-3">
        * Sure Abbott Rewards Program is restricted to consumers residing in
        Kuwait only.
      </p>
    </div>
  </div>
</section>
