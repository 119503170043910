import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() text = "";
  @Input() customClass = "";
  @Input() title = "";
  @Input() profilePage;
  constructor(private location: Location, private router: Router) {
    window.scrollTo({ top: 0 })
  }

  ngOnInit(): void {
  }

  onClickBack() {
    if (this.profilePage == 'profilePage') this.router.navigate(['/dashboard'])
    else this.location.back();
  }
}
