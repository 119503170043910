<section class="globalContainer bg-lighBlue pb70">
  <div class="dashboard-container pb70">
    <div class="dasboard-profile-card pb-4 rewards-profileBg">
      <div class="row mx-0 px30">
        <div class="transaction-backBtn" (click)="onClickBack()">
          <span>
            <i class="fa fa-angle-left"></i>
          </span>
          Back
        </div>
      </div>
      <p class="text-center voucher-title">Detail e-Voucher</p>
      <div class="voucher-section" *ngIf="!vochers">
        <p class="v-title">{{ name }}</p>
        <span class="v-sub-title">Code Voucher</span>
        <div class="voucher-box mb-2">
          <span class="voucher-code">{{ coupon }}</span>
          <span (click)="onClickCopy(coupon)" id="copy_txt"
            ><i class="fa fa-clipboard"></i> Copy</span
          >
        </div>

        <table class="table table-borderless">
          <tr>
            <td class="text-secondary">Issue Date</td>
            <td class="text-secondary">:</td>
            <td class="text-right text-grey">{{ issuedDate }}</td>
          </tr>

          <tr>
            <td class="text-secondary">Vocher Value</td>
            <td class="text-secondary">:</td>
            <td class="text-right text-grey">{{ name }}</td>
          </tr>

          <tr>
            <td class="text-secondary">Valid until</td>
            <td class="text-secondary">:</td>
            <td class="text-right text-grey">{{ expiry }}</td>
          </tr>
        </table>

        <!-- <div class="row">
                <div class="col-5">Issued Date</div>
                <div class="col-1">:</div>
                <div class="col-6">21 September 2022</div>
            </div> -->
      </div>
      <div class="voucher-section" *ngIf="vochers">
        <div *ngFor="let vocher of vocherData">
          <!-- <p class="v-title">{{ vocher.seriesName }}</p> -->
          <p></p>
          <span class="v-sub-title">Code Voucher</span>

          <div class="voucher-box mb-2">
            <span class="voucher-code">{{ vocher.code }}</span>
            <span (click)="onClickCopy(vocher.code)" id="copy_txt"
              ><i class="fa fa-clipboard"></i> Copy</span
            >
          </div>

          <table class="table table-borderless">
            <tr>
              <td class="text-secondary">Issue Date</td>
              <td class="text-secondary">:</td>
              <td class="text-right text-grey">{{ vocher.issued | date }}</td>
            </tr>

            <tr>
              <td class="text-secondary">Vocher value</td>
              <td class="text-secondary">:</td>
              <td class="text-right text-grey">{{ vocher.seriesName }}</td>
            </tr>

            <tr>
              <td class="text-secondary">Valid until</td>
              <td class="text-secondary">:</td>
              <td class="text-right text-grey">{{ vocher.expiry | date }}</td>
            </tr>
          </table>
        </div>

        <!-- <div class="row">
                <div class="col-5">Issued Date</div>
                <div class="col-1">:</div>
                <div class="col-6">21 September 2022</div>
            </div> -->
      </div>
    </div>
  </div>
</section>
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
