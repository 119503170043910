<section class="globalContainer bg-lighBlue pb70">
  <div class="dashboard-container pb70">
    <div class="dasboard-profile-card pb-4 rewards-profileBg">
      <div class="row mx-0 px30">
        <div class="col-6 point-text pl-0">
          <p class="fs20 fW600 mt-3">Rewards</p>
        </div>
        <div class="col-6 text-right pr-0">
          <p class="point-text">Total Reward Points</p>
          <p class="points">{{ loyaltyPoints }}</p>
        </div>
      </div>
    </div>
    <div class="rewards-landing my-4 px30 pb-5">
      <p class="color-black info-txt text-center">
        Purchase more & earn more points
      </p>

      <!-- ======= reward section started ======== -->
      <div
        class="reward-points-section customised"
        *ngFor="let product of productList; let i = index"
      >
        <div class="row m-0">
          <div class="col-5 col pr-15 border-r left-sec">
            <p class="points">
              <span class="rewards-pts">{{ product.intouchPoints }}</span
              ><br />Points
            </p>
          </div>
          <div class="col-7 col pl-15 right-sec">
            <p>
              <span class="rewards-text"> Redeem </span>
             <span class="rewards-text">Abbott Voucher worth</span>
              <span class="rewards-pts">{{ product.name }}</span>
              <a (click)="onClickGetReward(product)" class="click-here"
                >Click here</a
              >
            </p>
          </div>
        </div>
      </div>

      <!-- ======= reward section end ======== -->

      <!-- <div *ngFor="let product of productList; let i = index">
        <div class="rewards-brands-card row mx-0 br10 bg-white mb-4">
          <div class="col-4 pl-0">
            <div class="reward-points text-center">
              <p class="color-white m-0">{{ product.intouchPoints }} pts</p>
            </div>
            <div class="brand-image text-center">
              <img [src]="product?.imageUrl" alt="Brand Image" />
            </div>
          </div>
          <div class="col-8 brand-qty">
            <h4 class="fs16 font-darkblue mb-3 fW600">{{ product.name }}</h4>
            <div class="quantity">
              <p class="fs12 color-lightgrey mb-2">Quantity</p>
              <div class="position-relative">
                <input
                  disabled
                  type="number"
                  [(ngModel)]="product.quantity"
                  value="{{ product.quantity }}"
                  name="qty"
                  class="w-100 fs13 qty"
                />
                <img
                  class="increment"
                  (click)="onIncrement(i)"
                  src="../../../../assets/images/arrow-up.png"
                />
                <img
                  class="decrement"
                  [class.disabled]="product.disabled === true"
                  (click)="onDecrement(i)"
                  src="../../../../assets/images/arrow-down.png"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <button
              class="primaryBtn Btn btn-white fs13 fW500 hl35"
              (click)="onClickGetReward(product)"
              type="button"
            >
              Get this reward
            </button>
          </div>
        </div>
      </div> -->

      <!-- <div class="rewards-brands-card row mx-0 br10 bg-white mb-4">
        <div class="col-4 pl-0">
          <div class="reward-points text-center">
            <p class="color-white m-0">232 pts</p>
          </div>
          <div class="brand-image text-center">
            <img
              src="../../../../assets/images/pediasure-brand.png"
              alt="Brand Image"
            />
          </div>
        </div>
        <div class="col-8 brand-qty">
          <h4 class="fs16 font-blue mb-3">
            PediaSure<sup>®</sup> GOLD Vanilla 850g
          </h4>
          <div class="quantity">
            <p class="fs12 color-lightgrey mb-2">Quantity</p>
            <div class="position-relative">
              <input
                type="number"
                value="1"
                name="qty"
                class="w-100 fs13 qty"
              />
              <img
                class="increment"
                src="../../../../assets/images/arrow-up.png"
              />
              <img
                class="decrement"
                src="../../../../assets/images/arrow-down.png"
              />
            </div>
          </div>
        </div>
        <div class="col-12 mb-4">
          <button
            class="primaryBtn Btn btn-white fs13 fW500 hl35"
            [routerLink]="['/rewards/checkout']"
            type="button"
          >
            Get this reward
          </button>
        </div>
      </div> -->
    </div>
  </div>

  <!-- sorry you don't have enough points - pop up -->
  <div
    bsModal
    #popupRewardAddedToCart="bs-modal"
    class="modal fade px30"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="">
            <div class="text-left content fW900">
              <!-- <h4 class="font-darkblue fs20 text-center fW900">
                REDEEMING REWARDS
              </h4> -->

              <div class="">
                <p class="fs16">
                  Sorry, you don’t have enough points to redeem this reward.
                </p>
              </div>
            </div>
            <div class="yello-bg-card d-flex">
              <div class="prod-img">
                <div class="reward-points">
                  <p class="color-white m-0 text-center">
                    {{ rewardInfo?.intouchPoints }} pts
                  </p>
                </div>

                <img
                  class="online-img"
                  [src]="rewardInfo?.imageUrl"
                  *ngIf="rewardInfo?.imageUrl != null"
                />
                <img
                  class="gift-img"
                  src="assets/images/GALogoColored.png"
                  *ngIf="rewardInfo?.imageUrl == null"
                />
              </div>
              <div class="col-9 prod-content">
                <p class="mb-0 mt-0">{{ rewardInfo?.name }}</p>
                <div class="d-flex mt-0 font-darkblue fW900 fs12">
                  <span class="mt5">x</span>
                  <span class="mt5">1</span>
                </div>
              </div>
            </div>
            <div class="mt25">
              <div class="d-flex fs16 justify-content-between">
                <p class="">Points required</p>
                <p class="font-blue fW900">{{ pointsRequired }} pts</p>
              </div>
              <div class="d-flex fs16 justify-content-between">
                <p class="">Current points</p>
                <p class="font-blue fW900">{{ loyaltyPoints }} pts</p>
              </div>
              <div class="d-flex fs16 justify-content-between">
                <p class="">Additional points needed</p>
                <p class="font-red fW900">{{ additionalPointsNeeded }} pts</p>
              </div>
            </div>
            <button
              type="submit"
              class="primaryBtn-lightBlue-bg fW500 Btn mt-4"
              (click)="onClickClose()"
            >
              <strong>Close</strong>
            </button>
            <!-- <div class="mt-3 text-center">
              <p class="fs10 mb-1 color-grey">
                Per DOH-FDA CFRR Promo Permit XXXX s 2021
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
