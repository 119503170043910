import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Stepper from 'bs-stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../service/api.service';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { environment as env } from 'src/environments/environment';
import { BugsnagService } from '../services/bugsnag.service';

@Component({
  selector: 'app-rewards-checkout',
  templateUrl: './rewards-checkout.component.html',
  styleUrls: ['./rewards-checkout.component.scss'],
})
export class RewardsCheckoutComponent implements OnInit {

  customerData: any;
  customerName: string;
  loyaltyPoints: any;
  customerNamePresent = false;
  rewardInfo: any = {};
  count = 1;
  disabled = false;
  showSuccessScreen: boolean = false;
  rewardVisited: boolean = false;

  @ViewChild('popupRemoveItemFromCart', { static: true })
  popupRemoveItemFromCart: ModalDirective;
  brand: any;
  insufficientPoints: boolean = false;
  totalInTouchPoints: number;
  info: void;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) { }

  ngOnInit(): void {
    console.log(this.route.snapshot.params);
    this.rewardInfo.name = this.route.snapshot.params.name;
    this.rewardInfo.quantity = this.route.snapshot.params.quantity
    this.rewardInfo.intouchPoints = this.route.snapshot.params.intouchPoints
    this.rewardInfo.id = this.route.snapshot.params.id
    this.rewardInfo.expiry = this.route.snapshot.params.expiry
    this.rewardInfo.tier = this.route.snapshot.params.tier
    console.log(this.rewardInfo);
    this.brand = sessionStorage.getItem('currentBrand');
    this.count = parseInt(this.rewardInfo.quantity);
    this.getCustomer();
    this.rewardVisited = true;
    this.disabled = this.count <= 1 ? true : false;
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    if (!mobile || !token) {
      console.log('customer not loggged in');
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
        if (res['status'].code == 200) {
          this.spinner.hide();
          this.customerData = res['customers']['customer'][0];
          console.log(this.customerData);
          this.customerName =
            this.customerData.firstname + ' ' + this.customerData.lastname;
          this.customerNamePresent = true;
          console.log(this.customerNamePresent, 'pres');
          this.loyaltyPoints =
            this.customerData.loyalty_points.toLocaleString();
          // Block the user
          let block = res['customers']['customer'][0].custom_fields.field;
          let blockUser = block.filter((filt) => {
            return filt.name == 'block_count';
          });
          console.log(blockUser, 'blockUser===');
          if (blockUser[0]?.value == '1') {
            this.spinner.hide();
            sessionStorage.clear();
            this.router.navigate(['/block-notice']);
          }
          console.log(this.loyaltyPoints.toLocaleString());
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      }),
        (err) => {
          this.spinner.hide();
          console.log(err);
          this.bsService.notifyError(err);
        };
    }
  }

  onClickClose() {
    this.popupRemoveItemFromCart.hide();
  }

  onClickYes() {
    let category = this.rewardInfo.category;
    this.router.navigate(['rewards/brand']);
  }

  onClickRemove() {
    this.popupRemoveItemFromCart.show();
  }

  onIncrement(quantity) {
    quantity = parseInt(quantity) + 1;
    // this.rewardInfo.quantity = quantity
    this.count = quantity;
    this.disabled = this.count <= 1 ? true : false;
  }

  onDecrement(quantity) {
    quantity = parseInt(quantity) - 1;
    //  this.rewardInfo.quantity = quantity
    this.count = quantity;
    this.disabled = this.count <= 1 ? true : false;
  }

  onClickConfirm() {
    this.loyaltyPoints = this.loyaltyPoints.toLocaleString();
    this.totalInTouchPoints = this.rewardInfo.intouchPoints * this.count;
    console.log(this.totalInTouchPoints, this.count, this.loyaltyPoints);
    let newloytypoints = parseInt(this.loyaltyPoints.replace(',', ''));
    this.loyaltyPoints = newloytypoints;
    console.log(this.loyaltyPoints);
    console.log(this.totalInTouchPoints <= this.loyaltyPoints);

    if (this.totalInTouchPoints <= this.loyaltyPoints) {
      let time = new Date();
      let todayDate = moment(new Date()).format('YYYY-MM-DD HH:MM:SS');
      let getMobile = sessionStorage.getItem('mobileNo');
      let getAuthKey = sessionStorage.getItem('authToken');
      let formData = {
        notes: this.rewardInfo.tier,
        rewards: [
          {
            quantity: this.count,
            rewardId: this.rewardInfo.id,
          },
        ],
        redemptionTime: todayDate,
        transactionNumber: this.rewardInfo.id + '_' + time.getTime(),
      };
      this.spinner.show();
      let marwalissue
      this.apiService.marvalRewardIssue(formData, getMobile, getAuthKey)
        .pipe(first()).subscribe(
          (res: any) => {
            marwalissue = res;
            this.spinner.hide();
            if (res['status'].code == 200 || res['status'].succes == true) {
              console.log(res, 'marvalRewardIssue');
              this.info = res.rewards[0].intouch[0]
              this.getCustomer();
              let detail = {
                expiry: this.info['codeExpiry'],
                coupon: this.info['couponCode'],
                pointsRedeemed: this.info['pointsRedeemed'],
                name: this.rewardInfo.name
              }
              this.router.navigate(['/redeemption-success', detail])
            } else if (res['status'].code != 200) {
              this.apiService.showToasterError(res['status'].message)
              console.log(res['status'].code, ' status code');
              console.log(res['status'].message, ' status message');
              if (res['status'].code == 8003) {
                // this.pointsExceeded = true;
                // console.log(this.pointsExceeded, ' this.pointsExceeded ');
              }
            }
          }, err => {
            this.spinner.hide();
            console.log(err)
          }
        )
    } else {
      this.insufficientPoints = true;
    }
  }
}
