import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../service/api.service';
import { BugsnagService } from '../services/bugsnag.service';

@Component({
  selector: 'app-redeem-redeemption-success',
  templateUrl: './redeem-redeemption-success.component.html',
  styleUrls: ['./redeem-redeemption-success.component.scss']
})
export class RedeemRedeemptionSuccessComponent implements OnInit {

  customerNamePresent: any = false;
  data: any;
  name: any;
  issuedDate: string;
  coupon: any;
  expiry: string;
  customerData: any;
  customerName: string;
  loyaltyPoints: any;
  pointsRedeemed: any;
  vocherData = [];
  vochers: boolean = false;
  constructor(private route: ActivatedRoute,
    private toaster: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService, private bsService: BugsnagService) { }

  ngOnInit(): void {
    this.data = this.route.snapshot.params
    console.log("data: ", this.data)
    console.log(this.router.getCurrentNavigation())
    console.log(history?.state?.voucherData)
    this.vocherData = history?.state?.voucherData
    if (this.vocherData?.length >= 0) {
      this.vochers = true
    } else {
      this.vochers = false
    }
    this.vocherData = this.vocherData?.reverse()
    this.name = this.data?.name
    this.issuedDate = moment().format('DD MMM YYYY')
    this.pointsRedeemed = this.data?.pointsRedeemed
    this.coupon = this.data?.coupon
    this.expiry = moment(this.data?.expiry).format('DD MMM YYYY')
    this.getCustomer()
  }

  onClickCopy(coupon) {
    this.toaster.success(`Copied`)
    let copyText = document.getElementById("copy_txt");
    let input = document.createElement("textarea");
    input.value = coupon;
    document.body.appendChild(input);
    input.select();
    document.execCommand("Copy");
    input.remove();

  }
  onClickBack() {
    if (this.vochers) this.router.navigate(['/profile/transaction-history'])
    else this.router.navigate(['/rewards/brand'])
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    if (!mobile || !token) {
      console.log('customer not loggged in');
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
        if (res['status'].code == 200) {
          this.spinner.hide();
          this.customerData = res['customers']['customer'][0];
          console.log(this.customerData);
          this.customerName =
            this.customerData.firstname + ' ' + this.customerData.lastname;
          this.customerNamePresent = true;
          console.log(this.customerNamePresent, 'pres');
          this.loyaltyPoints =
            this.customerData.loyalty_points.toLocaleString();
          // Block the user
          let block = res['customers']['customer'][0].custom_fields.field;
          let blockUser = block.filter((filt) => {
            return filt.name == 'block_count';
          });
          console.log(blockUser, 'blockUser===');
          if (blockUser[0]?.value == '1') {
            this.spinner.hide();
            sessionStorage.clear();
            this.router.navigate(['/block-notice']);
          }
          console.log(this.loyaltyPoints.toLocaleString());
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      }),
        (err) => {
          this.spinner.hide();
          console.log(err);
          this.bsService.notifyError(err);
        };
    }
  }

}
