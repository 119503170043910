<app-header title="Manage recipient information"></app-header>
<section class="globalContainer px15 py-3">
  <div class="editAccount px15">
    <div class="formContainer edit-account-form">
      <form
        [formGroup]="addressForm"
        class="signup-Form mt40"
        (ngSubmit)="onSubmitAddressForm()"
      >
        <div class="fieldset">
          <div class="field">
            <label for="name">Recipient name</label>
            <input
              name="name"
              formControlName="recipientName"
              type="text"
              placeholder="Enter Full Name"
              [ngClass]="{
                'is-invalid':
                  submittedAddressForm &&
                  addressFormFormVal.recipientName.errors
              }"
            />
          </div>

          <div
            *ngIf="
              submittedAddressForm && addressFormFormVal.recipientName.errors
            "
            class="error"
          >
            <span *ngIf="addressFormFormVal.recipientName.errors.required"
              >Please enter recipient name</span
            >
          </div>
          <div class="field position-relative">
            <label for="mobNum">Recipient mobile number</label>
            <input
              name="mobNumber"
              class="form-control mobNumber"
              formControlName="recipientMobile"
              type="tel"
              [ngClass]="{
                'is-invalid':
                  submittedAddressForm &&
                  addressFormFormVal.recipientMobile.errors
              }"
            />
            <span class="mobPlaceholder d-flex align-items-center"
              >
              <!-- <img src="../../../assets/images/flag.png" /> -->
              +965
              <img src="assets/images/down-arrow.png" />
            </span>
            <img
              *ngIf="addressFormFormVal.recipientMobile.valid"
              class="mobVerified"
              src="assets/images/valid.png"
            />
          </div>

          <div
            *ngIf="
              submittedAddressForm && addressFormFormVal.recipientMobile.errors
            "
            class="error"
          >
            <span *ngIf="addressFormFormVal.recipientMobile.errors.required"
              >Please enter recipient mobile</span
            >
          </div>

          <div class="field">
            <label for="address" class="mb-0">Street Name</label>
            <!-- <p><span>Unit number, street name</span></p> -->
            <input
              name="address"
              type="text"
              formControlName="address"
              [ngClass]="{
                'is-invalid':
                  submittedAddressForm && addressFormFormVal.address.errors
              }"
            />
          </div>

          <div
            *ngIf="submittedAddressForm && addressFormFormVal.address.errors"
            class="error"
          >
            <span *ngIf="addressFormFormVal.address.errors.required"
              >Please enter street name</span
            >
          </div>

          <div class="field">
            <label for="address" class="mb-0">Unit Number </label>
            <!-- <p><span>Unit number, street name</span></p> -->
            <input
              name="unitnumber"
              type="text"
              formControlName="unitnumber"
              [ngClass]="{
                'is-invalid':
                  submittedAddressForm && addressFormFormVal.unitnumber.errors
              }"
            />
          </div>

          <div
            *ngIf="submittedAddressForm && addressFormFormVal.unitnumber.errors"
            class="error"
          >
            <span *ngIf="addressFormFormVal.unitnumber.errors.required"
              >Please enter unitnumber</span
            >
          </div>

          <div class="field">
            <label for="postcode">Postal code</label>
            <input
              name="postcode"
              type="number"
              formControlName="postalCode"
              (change)="postalCode($event)"
              [ngClass]="{
                'is-invalid':
                  (submittedAddressForm &&
                    addressFormFormVal.postalCode.errors) ||
                  invalidPostal
              }"
            />
          </div>

          <div
            *ngIf="submittedAddressForm && addressFormFormVal.postalCode.errors"
            class="error"
          >
            <span *ngIf="addressFormFormVal.postalCode.errors.required"
              >Please enter postal code</span
            >
          </div>
          <div class="error" *ngIf="invalidPostal">
            <span>Unable to deliver this location</span>
          </div>
          <div class="field mt50">
            <button type="submit" class="primaryBtn Btn">Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <app-menu [customerNamePresent]="customerNamePresent"></app-menu>
</section>
