<div class="menuContainer">
  <div class="menu-list">
    <a href="#" [routerLink]="['/dashboard']">
      <img src="assets/images/home.png" />
      Home
    </a>
  </div>
  <div class="menu-list">
    <a
      href="#"
      class="collapsed"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#about-Programme"
      aria-expanded="false"
      aria-controls="about-Programme"
    >
      <img src="assets/images/warning.png" />
      Program
    </a>
  </div>
  <div class="menu-list">
    <a (click)="onClickScan()">
      <img src="assets/images/prodimg/scan.png" />
    </a>
  </div>
  <div class="menu-list">
    <a (click)="onClickRewards()">
      <img src="assets/images/gift.png" />
      Rewards
    </a>
  </div>
  <div class="menu-list">
    <a (click)="onClickProfile()">
      <img src="assets/images/account.png" />
      Profile
    </a>
  </div>
</div>
<div class="about-menu-container pb70">
  <div class="about-program-card text-center">
    <button
      #button
      [class.toggleBtn]="url == 'about'"
      [class.toggleBtnCollapsed]="url != 'about'"
      class="collapsed"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#about-Programme"
      aria-expanded="false"
      aria-controls="about-Programme"
    >
      <img class="closed" src="assets/images/arrow-up.png" />
      <span class="closed closed mt-2 fs13 font-blue">About program</span>
      <span class="opened">
        <img class="mb-3" src="assets/images/arrow-down.png" />
        <!-- <img src="../../../assets/images/rewards-blue.png" /> -->
        <img class="width" src="assets/images/IMG_4062.jpg" />
      </span>
    </button>
    <div class="collapse" id="about-Programme">
      <div class="about-collapse-menu">
        <ul>
          <!-- <li>
            <a href="#" [routerLink]="['/about/family']"
              >About Sure Abbott Rewards Program
              <span><img src="../../../assets/images/arrow-right.png" /></span>
            </a>
          </li> -->
          <li>
            <a href="#" [routerLink]="['/about/faq']"
              >Frequently Asked Questions
              <span><img src="assets/images/arrow-right.png" /></span>
            </a>
          </li>
          <li>
            <a href="#" [routerLink]="['/about/terms']"
              >Terms and Conditions
              <span><img src="assets/images/arrow-right.png" /></span>
            </a>
          </li>
          <li>
            <a href="#" [routerLink]="['/about/privacy-policy']"
              >Privacy Policy
              <span><img src="assets/images/arrow-right.png" /></span>
            </a>
          </li>
          <li>
            <!-- <p class="color-lightgrey fs12 mb-0">SG.2021.21900.PDS.1 (v1.0)</p> -->
          </li>
        </ul>
        <div class="pediasure-ar">
          <span>يساعد طفلك علي النمو بشكل اطول*و اقوي ..</span
          ><a
            class="ps-link"
            target="_blank"
            href="https://pediasure-kwt.abbottnutrition.me/ar/pediasure.html"
          >
            لمعرفة المزيد
          </a>
          <img
            class="img-80"
            src="assets/images/pediasure-txt-img.png"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #notLoginOrSignup="bs-modal"
  class="modal fade customModalContainer px30 mt-30vh"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content br10 px15 py-3">
      <div class="modal-body pt-3 text-center">
        To access this feature, please log in or sign up to Abbott Family
        Rewards.
      </div>
      <div class="text-center m-3">
        <button
          [routerLink]="['/login-signup']"
          class="primaryBtn mb-2 fW100"
          type="submit"
        >
          Log in / sign up now
        </button>
        <button
          (click)="onClickCancel()"
          class="primaryBtn btn-white fW100"
          type="submit"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
