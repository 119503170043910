<app-header title="About program"></app-header>
<!-- <section class="familyContainer pb70">
  <div class="primary-container px30 pt-4 familyBG">
    <h4 class="fs20 color-yellow mt-3 mb-4">About Sure Abbott Rewards Program</h4>
    <div class="text-center mt40 mb-5">
      <img src="../../../assets/images/family-primary.png" />
    </div>
    <p class="color-white mb-4">
      <span class="color-yellow">Sure Abbott Rewards Program</span> is your way to
      collect points and rewards all in one place for you and your family's
      Abbott needs.
    </p>
    <h4 class="fs20 color-yellow mt40 mb-3">
      Why join the Sure Abbott Rewards Program?
    </h4>
    <p class="color-white">
      Make the most of your Abbott purchases when you scan and redeem your
      points for rewards. Learn more about your favorite Abbott
      products and find what's best for you and your family's health needs with
      Sure Abbott Rewards Program.
    </p>
  </div>
  <div class="secondary-container px30 py-4">
    <h4 class="fs20 font-darkblue mt-3 mb-4">
      How does Sure Abbott Rewards Program work?
    </h4>
    <iframe
      width="315"
      height="315"
      src="https://www.youtube.com/embed/8YrTCdCN2mo"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <p class="mt-4">
      Follow these <span class="font-darkblue">4 simple steps</span> to to enjoy
      earning points and redeeming rewards:
    </p>
    <ul class="familyList pl-0">
      <li>Buy an Abbott product* from official retailers</li>
      <li>Scan the QR Code at the bottom of the can to earn points</li>
      <li>Choose and redeem your rewards</li>
      <li>Enjoy your free delivery to your doorstep!**</li>
    </ul>
    <p class="color-lightgrey fs13 mt40 mb-3">
      * Currently only available on PediaSure<sup>®</sup>  Powder. Participating products
      must have QR code printed at the bottom of the can.
    </p>
    <p class="color-lightgrey fs13">** Allow 2-3 weeks for delivery</p>
  </div>
</section> -->
<!-- <app-bottom-menu></app-bottom-menu> -->
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
