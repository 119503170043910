import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {APP_BASE_HREF} from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ProfileComponent } from './profile/profile.component';
import { AccountComponent } from './profile/account/account.component';
import { EditaccountComponent } from './profile/account/editaccount/editaccount.component';
import { ChangeMobileComponent } from './profile/account/change-mobile/change-mobile.component';
import { FamilyRewardsComponent } from './about-rewards-programme/family-rewards/family-rewards.component';
import { FaqComponent } from './about-rewards-programme/faq/faq.component';
import { HeaderComponent } from './shared/header/header.component';
import { BottomMenuComponent } from './about-rewards-programme/bottom-menu/bottom-menu.component';
import { ChangePasswordComponent } from './profile/account/change-password/change-password.component';
import { ManageAddressComponent } from './profile/account/manage-address/manage-address.component';
import { TransactionHistoryComponent } from './profile/account/transaction-history/transaction-history.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { ProductListComponent } from './products/product-list/product-list.component';
import { ProductDetailComponent } from './products/product-detail/product-detail.component';
import { OtpComponent } from './login-signup/otp/otp.component';
import { ForgotPasswordComponent } from './login-signup/forgot-password/forgot-password.component';
import { CountdownModule } from 'ngx-countdown';
import { TestimonialComponent } from './products/testimonial/testimonial.component';
import { BuyLocalComponent } from './products/buy-local/buy-local.component';
import { LandingComponent } from './rewards/landing/landing.component';
import { RewardsBrandSelectionComponent } from './rewards/landing/rewards-brand-selection/rewards-brand-selection.component';
import { RewardsCheckoutComponent } from './rewards-checkout/rewards-checkout.component';
import { CameraComponent } from './scan/camera/camera.component';
import { SuccessComponent } from './scan/success/success.component';
import { DashboardComponent } from './scan/dashboard/dashboard.component';
import { DisclaimerComponent } from './scan/disclaimer/disclaimer.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { LoginComponent } from './login-signup/login/login.component';
import { SignUpComponent } from './login-signup/sign-up/sign-up.component';
import { LoginSignupComponent } from './login-signup/login-signup/login-signup.component';
import { SignupSuccessComponent } from './login-signup/signup-success/signup-success.component';
import { MenuComponent } from './shared/menu/menu.component';
import { InitialDashboardComponent } from './initial-dashboard/initial-dashboard.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { QrCodeScannerComponent } from './qr-code-scanner/qr-code-scanner.component';
import { TermsConditionsComponent } from './about-rewards-programme/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './about-rewards-programme/privacy-policy/privacy-policy.component';
import { PediasureGoldDetailComponent } from './products/pediasure-gold-detail/pediasure-gold-detail.component';
import { PediasureReadyToDrinkComponent } from './products/pediasure-ready-to-drink/pediasure-ready-to-drink.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FormsModule } from '@angular/forms';
import { PopupModule } from '@ciri/ngx-popup';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PediasureGoldSucroseFreeComponent } from './products/pediasure-gold-sucrose-free/pediasure-gold-sucrose-free.component';
import { ValidateReceiptComponent } from './validate-receipt/validate-receipt.component';
import { WebcamModule } from 'ngx-webcam';
import { TestQrComponent } from './test-qr/test-qr.component';
import { BlockNoticeComponent } from './block-notice/block-notice.component';
// import { StrengthMeterModule } from "ngx-strength-meter";
import { Ng9PasswordStrengthBarComponent } from './password-strength/password-strength.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NewqrComponent } from './newqr/newqr.component';
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RedeemRedeemptionSuccessComponent } from './redeem-redeemption-success/redeem-redeemption-success.component';
import { environment as env } from '../environments/environment';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { AuthInterceptor } from './_helpers/auth.interceptor';
import { PediasureOneVanillaNineComponent } from './products/pediasure-one-vanilla-nine/pediasure-one-vanilla-nine.component';
import { PediasureOneVanillaSixteenComponent } from './products/pediasure-one-vanilla-sixteen/pediasure-one-vanilla-sixteen.component';
import { PediasureOneChocolateFourComponent } from './products/pediasure-one-chocolate-four/pediasure-one-chocolate-four.component';
import { PediasureOneChocolateNineComponent } from './products/pediasure-one-chocolate-nine/pediasure-one-chocolate-nine.component';
import { PediasureThreeVanillaFourComponent } from './products/pediasure-three-vanilla-four/pediasure-three-vanilla-four.component';
import { PediasureThreeVanillaNineComponent } from './products/pediasure-three-vanilla-nine/pediasure-three-vanilla-nine.component';
import { PediasureThreeChocolateFourComponent } from './products/pediasure-three-chocolate-four/pediasure-three-chocolate-four.component';
import { PediasureThreeChocolateNineComponent } from './products/pediasure-three-chocolate-nine/pediasure-three-chocolate-nine.component';
Bugsnag.start({
  apiKey: '85614847b3a2a4a7f0edb81ca0df5ce5',
  appVersion: env.bugsnag_version,
  collectUserIp: false
})

export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    AccountComponent,
    EditaccountComponent,
    ChangeMobileComponent,
    FamilyRewardsComponent,
    FaqComponent,
    RedeemRedeemptionSuccessComponent,
    HeaderComponent,
    BottomMenuComponent,
    ChangePasswordComponent,
    ManageAddressComponent,
    TransactionHistoryComponent,
    ComingSoonComponent,
    ProductListComponent,
    ProductDetailComponent,
    OtpComponent,
    ForgotPasswordComponent,
    TestimonialComponent,
    BuyLocalComponent,
    LandingComponent,
    RewardsBrandSelectionComponent,
    RewardsCheckoutComponent,
    CameraComponent,
    SuccessComponent,
    DashboardComponent,
    DisclaimerComponent,
    WelcomeComponent,
    LoginComponent,
    SignUpComponent,
    LoginSignupComponent,
    SignupSuccessComponent,
    MenuComponent,
    InitialDashboardComponent,
    QrCodeScannerComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    PediasureGoldDetailComponent,
    PediasureReadyToDrinkComponent,
    PediasureGoldSucroseFreeComponent,
    ValidateReceiptComponent,
    TestQrComponent,
    BlockNoticeComponent,
    Ng9PasswordStrengthBarComponent,
    NewqrComponent,
    PediasureOneVanillaNineComponent,
    PediasureOneVanillaSixteenComponent,
    PediasureOneChocolateFourComponent,
    PediasureOneChocolateNineComponent,
    PediasureThreeVanillaFourComponent,
    PediasureThreeVanillaNineComponent,
    PediasureThreeChocolateFourComponent,
    PediasureThreeChocolateNineComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ImageCropperModule,
    //StrengthMeterModule,
    NgxSpinnerModule,
    ModalModule.forRoot(),
    NgOtpInputModule,
    CountdownModule,
    PopupModule,
    HttpClientModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    WebcamModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [HttpClientModule,BsDatepickerConfig,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {provide: APP_BASE_HREF, useValue: '/kw'}],
  bootstrap: [AppComponent],
})
export class AppModule { }
