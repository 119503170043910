import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { environment as env } from '../../../environments/environment';
import { BugsnagService } from '../../services/bugsnag.service';


@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
  customerData: any;
  customerName: string;
  suspiciousCount: number = 0;
  iSprintData: any;
  localIpAddress: any;
  latitude: string;
  longitude: string;
  confidence: string;
  scanCode: string;
  prodImage: any;
  weight: any;
  isWeightEmpty: boolean;
  disableContinueButton: boolean;
  suspiciousCountVal: string;
  isSuspicountAvailable: boolean = false;
  country: any;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService) {
    this.getCustomer()

    // this.isprintData =  JSON.parse(sessionStorage.getItem('iSprintData'));
  }

  ngOnInit(): void {
    // this.customerName = sessionStorage.getItem('customerName')
    // this.suspiciousCountVal = sessionStorage.getItem('suspiciousCount')
    // this.suspiciousCount = parseInt(this.suspiciousCountVal)

    this.disableContinueButton = false
    this.getIpAddress();
    this.getPosition();



    this.iSprintData = sessionStorage.getItem('iSprintData');
    this.iSprintData = JSON.parse(this.iSprintData);
    console.log(this.iSprintData, 'this.iSprintData ')
    this.scanCode = sessionStorage.getItem('scannedCode');

    console.log(this.scanCode, 'this.scanCode')

    this.weight = this.iSprintData?.size;
    //this.weight = '05X0200GRS';
    this.isWeightEmpty = this.isEmpty(this.weight)
    if (this.isWeightEmpty) {
      this.weight = this.weight.includes('GRS') ? this.weight.replace("GRS", "") : this.weight.replace("GR", "");
      if (this.weight.includes('X')) {
        let weightArray = this.weight.split('X');
        let noOfItems = weightArray[0];
        let quantity = weightArray[1];
        console.log(noOfItems, 'noOfItems', quantity, 'quantity')
        this.weight = (parseInt(quantity)) * noOfItems / 1000;
      } else {
        this.weight = (parseInt(this.weight)) / 1000
      }
    }
    else {
      this.weight = 0
    }
    console.log("weight: ", this.weight)
    this.weight = JSON.stringify(this.weight)
    sessionStorage.setItem('productWeight', this.weight)
    this.spinner.show();
    this.apiService.getGpasProductImage(this.iSprintData?.sku).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.prodImage = res.data[0]?.productimage;
        console.log(this.prodImage, 'getGpasProductImage')
      }, err => {
        this.bsService.notifyError(err);
        console.log(err)
        this.spinner.hide()
      });
  }

  isEmpty(weight) {
    if (weight == "" || weight == undefined || weight == null) {
      return false;
    } else {
      return true
    }
  }

  isEmptySuspicious(count) {
    return count == undefined || count == '' ? true : false
  }

  getIpAddress() {
    return fetch('https://api.ipify.org/?format=json')
      .then(results => results.json())
      .then(data => {
        this.localIpAddress = data.ip
        console.log("ip: ", this.localIpAddress)
      });
  }

  getPosition() {
    this.apiService.getPosition().then(pos => {
      let lat = pos.lat
      let lang = pos.lng
      let confidence = pos.accuracy
      this.latitude = JSON.stringify(lat)
      this.longitude = JSON.stringify(lang)
      this.confidence = JSON.stringify(confidence);
    })
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  onClickContinue() {
    this.isSuspicountAvailable = this.isEmptySuspicious(this.suspiciousCount)
    if (this.customerName) {
      this.disableContinueButton = true;

      //gpass
      let mobile = sessionStorage.getItem('mobileNo');
      this.spinner.show()
      let token = sessionStorage.getItem('authToken');
      this.country = this.iSprintData?.country?.toLowerCase();
      if (this.iSprintData?.is_isprint) {
        let parsedPrimaryValue = this.parseFunction(this.iSprintData.product_name).en_US;
        let prdname = parsedPrimaryValue;

        let formData = {
          "scan_code": this.scanCode,
          "mobile": mobile,
          "cap_device_id": env.DEVICE_ID,
          "customer_name": this.customerName,
          "suspicious_count": this.suspiciousCount,
          "product_name": prdname,
          "product_image": (this.iSprintData?.product_image == undefined || this.iSprintData?.product_image == null) ? 'null' : this.iSprintData?.product_image,
          "confidence": this.confidence,
          "child_count": "1",
          // "child_count": this.kidsLength,
          "weight": (this.weight == undefined || this.weight == null) ? '0' : this.weight,

          "custom_fields": [
            {
              "name": "translat",
              "value": this.latitude
            },
            {
              "name": "translong",
              "value": this.longitude
            },
            {
              "name": "productname",
              "value": prdname ? prdname : 'PediaSure',
            },
            {
              "name": 'category_code_trans',
              "value": prdname ? prdname : 'PediaSure',
            },
            {
              "name": 'sfproductcode',
              "value": this.iSprintData.sku ? this.iSprintData.sku : 'Null',
            },
          ]
        }
        console.log(formData, 'form data')
        if (this.country.startsWith('kuwait')) {
          this.apiService.postGpassEarnPoints(formData, token).pipe(first())
            .subscribe((res: any) => {
              this.spinner.hide()
              if (res['status'] == 'success') {
                console.log(res);
                this.router.navigate(['scan/success'])
              }

            }, err => {
              this.bsService.notifyError(err);
              this.spinner.hide()
              console.log(err)
              if (err?.error?.code == 420) {
                alert(err?.error?.message)
              }
              else {
                alert(err?.error?.message)
                console.log(err?.error?.message);
              }
            })
        } else {
          console.log('Non kuwait product is scanneds')
        }

      } else {
        this.apiService.getGpasProductImage(this.iSprintData.sku).subscribe(
          (res: any) => {
            console.log(res.data, 'getGpasProductImage')
            this.prodImage = res.data[0]?.image;
          })
        let formData = {
          "scan_code": this.scanCode,
          "caller_ip": this.localIpAddress,
          "mobile": mobile,
          "customer_name": this.customerName,
          "suspicious_count": this.suspiciousCount,
          "product_name": this.iSprintData.brand + ' ' + this.iSprintData.flavor + ' ' + this.iSprintData.size,
          "product_image": (this.prodImage == undefined || this.prodImage == null) ? 'null' : this.prodImage,
          "latitude": this.latitude,
          "longitude": this.longitude,
          "confidence": this.confidence,
          //   "child_count": this.kidsLength,
          "weight": (this.weight == undefined || this.weight == null) ? '0' : this.weight,
          "child_count": "1",
          "custom_fields": [
            {
              "name": "productname",
              "value": this.iSprintData.brand + ' ' + this.iSprintData.flavor + ' ' + this.iSprintData.size,
            },
            {
              "name": 'category_code_trans',
              "value": this.iSprintData.brand ? this.iSprintData.brand : 'PediaSure',
            },
            {
              "name": 'sfproductcode',
              "value": this.iSprintData.sku ? this.iSprintData.sku : 'Null',
            },
          ]
          //"weight": this.weight,
        }
        console.log(formData, 'gaps form data');
        this.spinner.show()
        if (this.country.startsWith('kuwait')) {
          this.apiService.postGpassEarnPoints(formData, token).pipe(first())
            .subscribe((res: any) => {
              this.spinner.hide()
              console.log(res);
              if (res['status'] == 'success') {
                this.router.navigate(['scan/success'])

              }
              //  else if (res['code'] == 400 && res['message'] == 'Given code already verified for this user') {
              //   alert('QR code has already been scanned')
              // }
              // else if (res['code'] == 420) {
              //   alert(res?.message)
              // }

            }, err => {
              this.bsService.notifyError(err);
              this.spinner.hide()
              console.log(err)
              if (err?.error?.code == 420) {
                alert(err?.error?.message)
                this.disableContinueButton = true;
              }
              else {
                alert(err?.error?.message)
                console.log(err?.error?.message);
              }
            })
        } else {
          console.log('Non kuwait product is scanned')
        }
      }
    } else {
      console.log('customer not available')
    }

  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe(res => {
      // Block the user
      let block = res['customers']['customer'][0].custom_fields.field;
      let blockUser = block.filter(filt => {
        return filt.name == 'block_count'
      });
      console.log(blockUser, 'blockUser===');
      if (blockUser[0]?.value == '1') {
        sessionStorage.clear();
        this.router.navigate(['/block-notice']);
      }
      this.spinner.hide();
      this.customerData = res['customers']['customer'][0];
      console.log(this.customerData);
      this.customerName = this.customerData.firstname + " " + this.customerData.lastname
      console.log(this.customerName);
      this.customerData.custom_fields.field.forEach(element => {
        if (element.name === "suspicious_count") {
          this.suspiciousCount = parseInt(element.value)
        }
      });
    }, err => {
      this.bsService.notifyError(err);
      console.log(err)
      this.spinner.hide()
    })
    console.log(this.suspiciousCount, "suspiciousCount")
  }

  onClickExit() {
    this.router.navigate(['dashboard'])
  }

}
