<section class="globalContainer pt-5">
  <div class="welcome-container">
    <div class="text-center d-flex justify-content-center align-items-center">
      <div class="rewards-header">
        <!-- <img src="../../assets/images/rewards-login.png" alt="Rewards" /> -->
        <img src="assets/images/Sure_Loyalty.png" alt="Rewards" />

      </div>
    </div>
    <div class="welcome-card login-container text-center mt-5 bg-Blue">
      <h1 class="fs32 color-white mb-5">Log In / Sign Up</h1>
      <p class="color-white mb-2">Enter your mobile number</p>
      <form [formGroup]="mobileForm" (ngSubmit)="onSubmitMobileForm()">
        <div class="position-relative login-Form">
          <div class="form-group mt-2">
            <input
              type="number"
              formControlName="mobileNumber"
              class="form-control my-0 mobNumber"
              placeholder="9xx-xxxx-xxxx"
              [ngClass]="{
                'is-invalid':
                  submittedMobileForm && mobileFormVal.mobileNumber.errors
              }"
            />
            <span class="mobPlaceholder d-flex align-items-center"
              >
              <!-- <img src="../../../assets/images/flag.png" /> -->
              +965
              <img src="assets/images/down-arrow.png" />
            </span>
            <img
              *ngIf="mobileForm.valid"
              class="mobVerified"
              src="assets/images/valid.png"
            />
          </div>
        </div>

        <div
          *ngIf="submittedMobileForm && mobileFormVal.mobileNumber.errors"
          class="error"
        >
          <span *ngIf="mobileFormVal.mobileNumber.errors.required"
            >Please enter mobile number</span
          >
          <span *ngIf="mobileFormVal.mobileNumber.errors.pattern"
            >Invalid number</span
          >
        </div>

        <button type="submit" class="primaryBtn bg-secondaryBlue Btn mt-5">
          Continue
        </button>
      </form>
    </div>
  </div>
</section>
