<div class="container">
    <div class="logo"></div>
    <div class="name">Simple Sample</div>
    <div id="description" class="description" *ngIf="showDescription">
      Start the scanner and then aim at a barcode to get the encoded data.
    </div>
  
    <div id="lib-loading" *ngIf="!scannerReady">
      <br />Library is loading, please wait...
    </div>
  
    <button id="barcode-picker-starter-button" *ngIf="showButton" (click)="startBarcodePicker()">
      Start Scanning
    </button>
  
    <div id="barcode-result" class="result-text">Scanned Code <strong>{{ result }}</strong></div>
  
    <scandit-barcode-picker
      #barcodePicker
      id="barcode-picker"
      configure.licenseKey="Ac7xBhkcO9E+Kq6uB0OMI/ovSijWHfbwe0eo9Ggle/mkT3HqcmgzwIRrQflOFQ7hyWJwyVRILIY5V0fqkXWyCfJ6MR49dvoMP2SGy9ZqLL1MLdyc6jOqYBUqNdyMGhxTK7gScTIipJP4tPQZIHcvWWO1Gy1isGfIWu+YvhSxCfKNmRqais+hPii6dz9KFXd3cmSLAh1AEg/cT/OCaVs3rEnPtTF8fCEJZgtou3JynWtaMpCUYSU8drF3UtOTs4rmhmXTIcfD1fbkOl3pbrB8Mw/lHw8BRRIoEO+rmN6FTD+Vup6sebWLqr805u24EXJrRIYx9BXfkrmVza53S5UR9Pcy74I+6o6m3+ak+z9O75VvaoVmyOcvQsqHW4ZzcUPJzRo6cIpqnKIf2QUomQb4zWBw9GReiRAmELHnLEeA1KLdU0CF5x908f8EArroWNjQXW6dOM21tB8SdVVkg6kxk5ntC/enB3gyFEgLVTlvWr0zaMqd0vNzTU2pjwwIt62VI5tjfH5Ne9kVQSqTzXHRYDeC2SpzqjrdNROZ9bOg/QcGXl0CFw7xX248MnAO5nhiRuvPS+paLbHz2EOWeN/xzpXz0Fygoz47ZYaLZP7wKlJitOiXVzxuYnq6+HzR2uyV725IBe37qGkaQ7lL3LbRIhgpJj02wxMmr9kvlzzQShr1GokCmhsDSG6NpPvzcWt4FuqYMGgAdMB3xJ94ADauYbubJcrFXQnqr86e98lvA8x3VC99aczIgdouIFuan4CuRtiziQkPGb80K5Fm+JLOIJJwqJTcS9vzvd4JLP03DQ=="
      configure.engineLocation="https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/"
      scanningPaused="true"
      visible="false"
      playSoundOnScan="true"
      vibrateOnScan="true"
      scanSettings.enabledSymbologies='["ean8", "ean13", "upca", "upce"]'
      (ready)="onReady()"
      (scan)="onScan($event)">
    </scandit-barcode-picker>
  </div>
  