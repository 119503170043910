import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/service/api.service';
import { BugsnagService } from '../../services/bugsnag.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  customerData: any;
  customerName: string;
  loyaltyPoints: any;
  customerNamePresent = false;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService) { }

  ngOnInit(): void {
    this.getCustomer()
  }


  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    if (!mobile || !token) {
      console.log("customer not loggged in")
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe(res => {
        if (res['status'].code == 200) {
          this.spinner.hide();
          this.customerData = res['customers']['customer'][0];
          console.log(this.customerData);
          this.customerName = this.customerData.firstname + " " + this.customerData.lastname
          this.customerNamePresent = true;
          this.loyaltyPoints = this.customerData.loyalty_points
          // Block the user
          let block = res['customers']['customer'][0].custom_fields.field;
          let blockUser = block.filter(filt => {
            return filt.name == 'block_count'
          });
          console.log(blockUser, 'blockUser===');
          if (blockUser[0]?.value == '1') {
            this.spinner.hide();
            sessionStorage.clear();
            this.router.navigate(['/block-notice']);
          }
          console.log(this.loyaltyPoints)
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;

        }

      }), err => {
        this.bsService.notifyError(err);
        this.spinner.hide();
        console.log(err)
      }
    }


  }

  onClickAccoutLogin() {
    this.router.navigate(['login-signup'])
  }

}
