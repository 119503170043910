<app-header title="Buy Local"></app-header>
<section class="globalContainer px30 pt-4 pb70">
  <div class="buyLocal-container">
    <h4 class="fs20 font-darkblue mb-4">
      PediaSure<sup>®</sup> GOLD is widely available in Kuwait
    </h4>
    <img
      class="w-100"
      src="assets/images/singpore-trust.png"
      alt="Singapore Trust"
    />
    <p class="mt-4 mb-3">
      PediaSure<sup>®</sup> GOLD is available at authorized sellers across all
      major supermarkets and online from official sources such as Abbott’s
      flagship store on Lazada.
    </p>
    <p>
      When shopping online, select PediaSure<sup>®</sup> GOLD with the Kuwait
      Trust logo, which confirms that the product is made, stored and sold in
      Kuwait.
    </p>
  </div>
  <app-menu [customerNamePresent]="customerNamePresent"></app-menu>
</section>
