import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { environment as env } from 'src/environments/environment';
import { BugsnagService } from '../../../services/bugsnag.service';

@Component({
  selector: 'app-manage-address',
  templateUrl: './manage-address.component.html',
  styleUrls: ['./manage-address.component.scss'],
})
export class ManageAddressComponent implements OnInit {
  addressForm: FormGroup;
  submittedAddressForm: boolean = false;
  showAddressForm: boolean = false;

  customerData: any;
  addressData = {
    recipientName: '',
    phoneNumber: '',
    address: '',
    postalCode: '',
    unitnumber: ''
  };
  recipientname: any;
  recipientzip: any;
  recipientaddress: any;
  recipientphone: any;
  unitnumber: any;
  postalCodeVal: any;
  invalidPostalCode: boolean = false;
  invalidPostal: boolean = false
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) { }

  ngOnInit(): void {
    this.addressForm = this.formBuilder.group({
      recipientName: ['', Validators.required],
      recipientMobile: [
        '',
        [
          Validators.required,
          Validators.pattern(`^((\\+965-?)|0)?[0-9]{${env.MINMOBNUM},${env.MAXMOBNUM}}$`),
        ],
      ],
      address: ['', Validators.required],
      unitnumber: ['', Validators.required],
      postalCode: ['', Validators.required],
    });
    this.getCustomer()
  }

  postalCode(event) {
    console.log(event.target.value, 'postal code');
    this.postalCodeVal = event.target.value;
  }

  get addressFormFormVal() {
    return this.addressForm.controls;
  }
  customerNamePresent: any = true;
  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
      this.spinner.hide();
      if (res['status'].code == 200) {
        this.customerData = res['customers']['customer'][0];
        this.customerNamePresent = true;
        this.customerData.custom_fields.field.forEach(ele => {
          if (ele.name == 'recipientname')
            this.recipientname = ele.value
          if (ele.name === 'recipientzip') this.recipientzip = ele.value;
          if (ele.name === 'recipientaddress') this.recipientaddress = ele.value;
          if (ele.name === 'unitnumber') this.unitnumber = ele.value;
          if (ele.name === 'recipientphone')
            this.recipientphone = ele.value;
        });
        this.addressForm.patchValue({
          recipientName: this.recipientname,
          recipientMobile: this.recipientphone,
          address: this.recipientaddress,
          unitnumber: this.unitnumber,
          postalCode: this.recipientzip,
        })
      } else {
        this.spinner.hide();
        this.customerNamePresent = false;
      }
    }, err => {
      this.bsService.notifyError(err);
      console.log(err)
      this.spinner.hide()
    });
  }
  getUserForm(addressData) {
    return {
      root: {
        customer: [
          {
            //mobile: this.customerData.mobile,
            custom_fields: {
              field: [
                {
                  name: 'recipientname',
                  value: addressData.recipientName,
                },
                {
                  name: 'recipientphone',
                  value: addressData.recipientMobile,
                },
                {
                  name: 'recipientzip',
                  value: addressData.postalCode,
                },
                {
                  name: 'recipientaddress',
                  value: addressData.address,
                },
                {
                  name: 'unitnumber',
                  value: addressData.unitnumber,
                },
              ],
            },
          },
        ],
      },
    };
  }

  onSubmitAddressForm() {
    this.submittedAddressForm = true;
    if (this.addressForm.invalid) {
      return;
    } else {
      // this.apiService.getPostalCode(this.postalCodeVal).subscribe((postal: any) => {
      //   this.spinner.hide();
      //   let findPostal = postal?.data
      //   console.log(findPostal, 'findPostal');
      //   let postalCode = postal?.data[0]?.pincode;

      //   findPostal.filter(pos => {
      //     let finalPostal = pos?.pincode == this.postalCodeVal;
      //     console.log(finalPostal, 'finalPostal')

      //     if (finalPostal) {
      //       this.invalidPostalCode = true;
      //       console.log(this.invalidPostalCode, 'this.invalidPostalCode')
      //     }

      //   })
      //   if (findPostal?.length == 0) {
      //     this.invalidPostalCode = false;
      //     console.log(this.invalidPostalCode, 'this.invalidPostalCode')
      //   }
      //   if (this.invalidPostalCode == false) {
      //     this.invalidPostal = false;
      //     console.log('form');
      let addressData = this.addressForm.value;
      let filledFormValues = this.getUserForm(addressData);
      let getMobile = sessionStorage.getItem('mobileNo');
      let token = sessionStorage.getItem('authToken');
      this.spinner.show();
      this.apiService
        .updateCapCustomer(filledFormValues, getMobile, token)
        .pipe(first())
        .subscribe(
          (res) => {
            if (res['status'].code == 200) {
              this.spinner.hide();
              console.log('updated');

              this.showAddressForm = false;
              this.addressData.recipientName = addressData.recipientName;
              this.addressData.phoneNumber = addressData.phoneNumber;
              this.addressData.address = addressData.address;
              this.addressData.unitnumber = addressData.unitnumber;

              this.addressData.postalCode = addressData.postalCode;
            }
          },
          (err) => {
            this.bsService.notifyError(err);
            this.spinner.hide();
            console.log(err, '=== customer update error =======');
          }
        );
      //   } else if (this.invalidPostalCode == true) {
      //     this.invalidPostal = true;
      //   }
      // })



    }
  }
}
