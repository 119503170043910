<app-header title="Edit account information"></app-header>
<section class="globalContainer px15 py-5">
  <div class="editAccount px15">
    <div class="formContainer edit-account-form">
      <form
        [formGroup]="editAccountForm"
        (ngSubmit)="onSubmitEditAccountForm()"
      >
        <div class="fieldset">
          <div class="field">
            <label for="name">Full Name</label>
            <input
              formControlName="fullName"
              name="name"
              type="text"
              placeholder="Enter Full Name"
              [ngClass]="{
                'is-invalid':
                  submittedAccountForm && editAccountFormVal.fullName.errors
              }"
            />
          </div>
          <div
            *ngIf="submittedAccountForm && editAccountFormVal.fullName.errors"
            class="error"
          >
            <span *ngIf="editAccountFormVal.fullName.errors.required"
              >Please enter full name</span
            >
          </div>
          <div class="field">
            <label for="email">Email<span>(optional)</span></label>
            <input formControlName="email" name="email" type="email" />
          </div>
          <div
            *ngIf="submittedAccountForm && editAccountFormVal.email.errors"
            class="error-msg"
          >
            <!-- <span class="red" *ngIf="editAccountFormVal.email.errors.required"
              >Please enter mail id</span
            > -->
            <span *ngIf="editAccountFormVal.email.errors.pattern" class="red"
              >Please enter valid mail id</span
            >
          </div>
          <div class="error" *ngIf="emailAlreadyExists">
            Email cannot be saved as it has been used by another user.
          </div>

          <div class="field">
            <label for="Child name">Child Name</label>
            <input
              formControlName="childName"
              name="name"
              type="text"
              placeholder="Enter Child Name"
            />
          </div>

          <div class="field">
            <label for="Child name">Child Date of Birth</label>
            <input
              formControlName="childDOB"
              name="name"
              type="text"
              placeholder="Enter Child DOB"
              placeholder="Select Date"
              value="Select Date"
              bsDatepicker
              [maxDate]="maxDate"
              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY' }"      
              [ngClass]="{
                'is-invalid':
                  submittedAccountForm && editAccountFormVal.childDOB.errors
              }"
            />
            <div class="eyeIcon calendarIcon">
              <img
                src="assets/images/calendar-icon.png"
                alt="calendar"
              />
            </div>
</div>
            <div
            *ngIf="submittedAccountForm && editAccountFormVal.childDOB.errors"
            class="error"
          >
            <span *ngIf="editAccountFormVal.childDOB.errors.required"
              >Please enter child Date of Birth</span
            >
            <span *ngIf="editAccountFormVal.childDOB.errors.maxAgeIs13"
              >Maximum age is 13</span
            >
            <span *ngIf="editAccountFormVal.childDOB.errors.maxAgeIsSixMonths"
              >Child should be atleast six months old</span
            >
          </div>

         
          
          <!-- <div
            *ngIf="submittedAccountForm && editAccountFormVal.email.errors"
            class="error"
          >
            <span *ngIf="editAccountFormVal.email.errors.email"
              >Email is invalid</span
            >
          </div> -->
        </div>
        <div class="field mt50">
          <button type="submit" class="primaryBtn Btn">Save</button>
        </div>
      </form>
    </div>
  </div>
  <app-menu [customerNamePresent]="customerNamePresent"></app-menu>
</section>
