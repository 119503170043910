
import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import * as ScanditSDK from "scandit-sdk";
import { BarcodePicker, ScanResult } from "scandit-sdk";

@Component({
  selector: 'app-newqr',
  templateUrl: './newqr.component.html',
  styleUrls: ['./newqr.component.scss']
})
export class NewqrComponent implements OnInit {

  public scannerReady = false;
  public showButton = false;
  public showDescription = true;
  public result = "";

  @ViewChild("barcodePicker") barcodePickerElement: ElementRef<HTMLDivElement & { barcodePicker: BarcodePicker }>;

  ngOnInit(){
    
  }

  public onReady(): void {
    this.scannerReady = true;
    this.showButton = true;
  }

  public onScan(scanResult: { detail: ScanResult }): void {
    const calculatedString = scanResult.detail.barcodes.reduce((result, barcode) => {
      return `${result} ${ScanditSDK.Barcode.Symbology.toHumanizedName(barcode.symbology)} : ${barcode.data}`;
    }, "");

    this.result = calculatedString;
  }

  public startBarcodePicker(): void {
    this.showButton = false;
    this.showDescription = false;

    this.barcodePickerElement.nativeElement.barcodePicker.setVisible(true).resumeScanning();
  }

}

