import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-rewards-brand-selection',
  templateUrl: './rewards-brand-selection.component.html',
  styleUrls: ['./rewards-brand-selection.component.scss'],
})
export class RewardsBrandSelectionComponent implements OnInit {
  productName: any;
  productList = [];
  rewardsList: any;
  count = 1;
  disabled = false;
  customerData: any;
  loyaltyPoints: any;
  customerNamePresent: any = false;
  @ViewChild("popupRewardAddedToCart", { static: true })
  popupRewardAddedToCart: ModalDirective;
  rewardInfo: any;
  pointsRequired: number;
  additionalPointsNeeded: number;
  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.disabled = this.count <= 1 ? true : false;
    this.getCustomer();
    this.productName = this.route.snapshot.params.name;
    this.getRewardsList();
  }

  getRewardsList() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    this.spinner.show();
    this.apiService
      .getMarvalRewardDetails(mobile, token)
      .pipe(first())
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res['status'].code == 200) {
          this.rewardsList = res['rewardList'];
          console.log('rewardsList: ', this.rewardsList);
          this.productList = this.rewardsList
          this.productList = this.productList.reverse()
          // this.rewardsList.forEach((reward: any) => {
          //   reward.quantity = 1
          //   reward.disabled = true
          //   reward.category = "pediasure"
          //   this.productList.push(reward);

          // });
          // if (this.productName == 'ensure') {
          //   this.productName = 'Ensure';
          //   this.ensureProductList();
          // } 
          //  if (this.productName == 'pediasure') {
          // this.productName = 'PediaSure';
          // this.pediasureProductList();
          // }
          // else if (this.productName == 'glucerna') {
          //   this.productName = 'Glucerna';
          //   this.glucernaProductList();
          // } 
          // else if (this.productName == 'similac') {
          //   this.productName = 'Similac';
          //   this.similacProductList();
          // }
        }
      }, err => {
        console.log(err);
        this.spinner.hide()
      });
  }

  ensureProductList() {
    this.rewardsList.forEach((reward: any) => {
      if (reward.categoryList[0].name == 'ensure') {
        reward.quantity = 1
        reward.disabled = true
        reward.category = "ensure"
        this.productList.push(reward);
      }
    });
  }

  pediasureProductList() {
    this.rewardsList.forEach((reward: any) => {
      reward.quantity = 1
      reward.disabled = true
      reward.category = "pediasure"
      this.productList.push(reward);

    });
  }

  similacProductList() {
    this.rewardsList.forEach((reward: any) => {
      if (reward.categoryList[0].name == 'similac') {
        reward.quantity = 1
        reward.disabled = true
        reward.category = "similac"

        this.productList.push(reward);
      }
    });
  }

  glucernaProductList() {
    this.rewardsList.forEach((reward: any) => {
      if (reward.categoryList[0].name == 'glucerna') {
        reward.quantity = 1
        reward.disabled = true
        reward.category = "glucerna"

        this.productList.push(reward);
      }
    });
  }

  onIncrement(index) {
    this.productList[index].quantity += 1
    this.productList[index].disabled = this.productList[index].quantity <= 1 ? true : false;
  }

  onDecrement(index) {
    this.productList[index].quantity -= 1
    this.productList[index].disabled = this.productList[index].quantity <= 1 ? true : false;
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe(
      (res) => {
        this.spinner.hide()
        if (res['status'].code == 200) {
          this.spinner.hide();
          this.customerData = res['customers']['customer'][0];
          this.loyaltyPoints = this.customerData.loyalty_points;
          this.customerNamePresent = true;
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide()
      }
    );
  }

  onClickGetReward(reward) {
    this.rewardInfo = reward;
    let totalIntouchPoints = 1 * reward.intouchPoints;
    console.log(totalIntouchPoints);
    this.pointsRequired = totalIntouchPoints;
    this.additionalPointsNeeded = totalIntouchPoints - this.loyaltyPoints;
    console.log(reward);
    if (this.loyaltyPoints < this.pointsRequired) {
      this.popupRewardAddedToCart.show();
    } else {

      this.router.navigate(['rewards/checkout', {
        name: reward.name,
        quantity: 1,
        intouchPoints: 1 * reward.intouchPoints,
        id: reward.id,
        expiry: reward.endTime,
        tier: reward.tier
      }])

    }
  }

  onClickClose() {
    this.popupRewardAddedToCart.hide()
  }


}
