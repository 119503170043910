<app-header title="Privacy Policy"></app-header>
<section class="termsContainer pb70 px30">
  <h1 class="fs20 font-darkblue mt40">Privacy Policy</h1>
  <ul class="disc-inside px-0 my-4 pb70">
    <!-- <li> -->
    <p>
      This Privacy Policy applies to residents of Kuwait, Saudi Arabia and the United Arab Emirates. If you are a
      resident of another country, please
      <a class="font-blue" href="http://www.abbott.com/global-sites.html"> click here</a>
       for applicable information.
    </p>

    <p>
      This Privacy Policy explains how Abbott handles personal information that we collect online, through our websites
      and applications (apps) and through our offline interactions with you (collectively, the &quot;Abbott
      Services&quot;). This
      Privacy Policy does not apply to information collected from residents of countries other than the United States,
      or to third-party websites to which Abbott may link. Your use of Abbott Services which link to this Privacy Policy
      are subject to this Privacy Policy and the
      <a class="font-blue" href="http://www.abbott.com/online-terms-and-conditions.html">Terms of Use</a>.
    </p>

    <p>
      Please read this Privacy Policy before using the Abbott Services or submitting personal information to us.
    </p>

    <p>
      <i>On occasion, Abbott may offer apps, special programs, activities, events or promotions (&quot;Programs&quot;)
        that have
        unique or additional specific terms, privacy notices and/or consent forms that explain how any information you
        provide will be processed in connection with the Programs. You should review the applicable terms before
        interacting or participating in the Programs.</i>
    </p>

    <p><span class="subheading">Personal Information</span></p>

    <p>
      &quot;Personal information&quot; is information that identifies you as an individual or relates to an identifiable
      individual. Examples of personal information include your name, your postal address and your telephone number.
    </p>

    <p>
      <span class="subheading">How Does Abbott Collect Personal Information?</span>
    </p>

    <p>Abbott collects information in a few different ways:</p>

    <ul>
      <li>Information you submit or choose to provide</li>
      <li>
        Information you give us permission to collect from you or another source
      </li>
      <li>From Health Care Professionals</li>
      <li>Through our websites and mobile apps</li>
      <li>From contract research organizations and clinical trial investigators</li>
      <li>
        From your participation in Abbott programs or clinical trials
      </li>
      <li>
        From third party service providers, data brokers or business partners
      </li>
      <li>
        From industry and patient groups and associations
      </li>
      <li>Automatically from devices you use to connect to Abbott Services (See &quot;Cookies and Other Tools&quot;
        below)</li>
      <li>
        Information provided by Abbott partners
      </li>
      <li>From public sources including, but not limited to, social media and other websites that enable social sharing
      </li>
      <li>
        Information from government agencies, public or third party information sources
      </li>

    </ul>

    <p>
      From time to time, we may use or augment the personal information we have about you obtained from other sources,
      such as public databases, social media platforms and other third parties. We may also combine information we get
      from a third-party with information we already have, and we may combine information that we have collected offline
      with information we collect online.
    </p>

    <p>
      If you choose to submit any personal information relating to other people, you represent that you have the
      authority to do so and permit us to use the information in accordance with this Policy.
    </p>

    <p>
      <span class="subheading">Information Abbott Collects</span><br />
      Abbott may collect the following information, depending on what product and service you use:
    </p>

    <ul>
      <li>
        Contact information and preferences (such as name, email address, mailing address, phone number, and emergency
        contact information)
      </li>
      <li>
        Biographical and demographic information (such as date of birth, age, gender, ethnicity, marital status, and
        sexual orientation)
      </li>
      <li>
        Health and medical information (such as medical insurance details, information about physical and mental health
        conditions and diagnoses, treatments for medical conditions, genetic information, family medical history and
        medications an individual may take, including the dosage, timing, and frequency) we collect in connection with
        managing clinical trials, conducting research, providing patient support programs, distributing and marketing
        our products, managing compassionate use and expanded access programs and tracking adverse event reports
      </li>
      <li>
        Information regarding parents or legal guardians
      </li>
      <li>
        Any information you provide in connection with applying to or participating in a patient assistance or support
        program (such as financial need information)
      </li>
      <li>
        Payment-related information we need to complete a transaction (such as credit card number and financial account
        information)
      </li>
      <li>
        Username and password that you may select in connection with establishing an account on our websites or mobile
        apps
      </li>
      <li>
        Your photograph, social media handle or digital or electronic signature
      </li>
      <li>Publicly available information</li>
      <li>
        Internet activity, such as your Internet Protocol (&quot;IP&quot;) address, browsing history, your search
        history and
        information on your interaction with the Abbott Services and our advertisements
      </li>
      <li>Device information
      </li>
      <li>
        Geolocation data, e.g., your physical location or movements (See &quot;Cookies and Other Tools&quot; below)

      </li>
      <li>
        Inferences drawn from other personal information listed above, to create a profile reflecting your preferences,
        characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities and
        aptitudes

      </li>
      <li>
        If you are accessing Abbott websites or apps on your mobile device, you may also choose to provide us with
        location data. To protect your privacy, you should not provide Abbott with any information that is not
        specifically requested

      </li>
      <li>Any adverse event information
      </li>

    </ul>


    <p>
      <span class="subheading">IF YOU ARE A HEALTH CARE PROFFESSIONAL, WE MAY ALSO COLLECT:
      </span>
    </p>

    <ul>
      <li>
        Professional credentials, educational and professional history, institutional and government affiliations,
        information included on a resume or curriculum vitae, education and work history (such as work experience,
        education and languages spoken)

      </li>
      <li>
        Information about the Abbott programs, products and activities with which you have engaged

      </li>
      <li>
        Details about our interactions with you, your prescribing of our products and the agreements you have executed
        with us; including remittance or other financial information

      </li>
      <li>
        Any information you choose to provide about yourself or your household

      </li>
      <li>
        Information collected in connection with Abbott hosted events or activities you attend or participate in

      </li>
      <li>
        Publicly-available information related to your practice, such as license information, disciplinary history,
        prior litigation and regulatory proceedings, and other due diligence related information

      </li>
    </ul>

    <p>
      We may use the personal information we collect about you with information obtained from other sources, such as
      public databases, social media platforms and other third parties. For example, we may use public information to
      confirm contact or financial information, to verify licensure of Health Care Professionals or to better understand
      your interests by associating demographic information with the information you have provided.


    </p>

    <p>
      <span class="subheading">HOW DOES ABBOTT USE PERSONAL INFORMATION?
      </span>
    </p>

    <p>
      How Abbott uses your information depends on how you interact with the Abbott Services. Generally, Abbott may also
      use your information to:


    </p>



    <ul>
      <li>Provide our products and services to you and make them better</li>
      <li>Develop new products and services</li>
      <li>Fulfill your requests</li>
      <li>Provide customer service</li>
      <li>Send you administrative communications regarding Abbott’s products or Abbott Services</li>
      <li>Notify you about special promotions or offers that may be of interest to you</li>
      <li>Invite you to participate in surveys about our products or services</li>
      <li>Better understand the interests of our customers and visitors who use Abbott Services</li>
      <li>Maintain Abbott’s online services such as websites and apps</li>
      <li>Monitor, detect and prevent fraud, improper activity and ensure Abbott’s policy compliance</li>
      <li>Conduct research including secondary and follow-on research</li>
      <li>Determine an individual’s eligibility to participate in Abbott programs or clinical trials</li>
      <li>Process payments</li>
      <li>Evaluate how you and other users interact with our online services</li>
      <li>Protect Abbott and our users</li>
      <li>Secure our online services including our network, websites and apps</li>
      <li>Conduct audits related to our current interactions with you</li>
      <li>Debug the site and service to identify and repair errors that impair existing intended functionality</li>
      <li>Defend ourselves in litigation and investigations and to prosecute litigations</li>
      <li>Comply with our legal and regulatory obligations</li>
    </ul>

    <p>
      Abbott may also use information collected from you or about you to offer you customized content, including to:
    </p>

    <ul>
      <li>Recognize new or past visitors to our websites or apps</li>
      <li>Remember your personal preferences</li>
      <li>Show you more relevant and meaningful advertisements based on your interests</li>
    </ul>

    <p>
      Abbott may also use your information for other purposes disclosed to you from time to time.
    </p>

    <p>
      In addition, some of our applications and mobile-optimized sites may have location-based features. To deliver
      these features, we may collect and use location data provided by your mobile device. For example, we may offer
      shopping features that allow you to get information about local merchants. By using such applications, you consent
      to the collection and use of your mobile device location data for purposes of delivering these location-based
      features.
    </p>

    <p>
      We may use personal information collected from any of the sources listed in the &quot;How Does Abbott Collect
      Personal Information?&quot; section of this policy for our internal business purposes, such as data analysis,
      audits, developing new products, enhancing our websites and apps, improving our Abbott Services, identifying usage
      trends and determining the effectiveness of our promotional campaigns.
    </p>

    <p>
      We may aggregate and/or de-identify data about customers and site visitors and use it for any purpose, including
      product and service development and improvement activities.
    </p>

    <p>
      <span class="subheading">DOES ABBOTT SHARE PERSONAL INFORMATION?
      </span>
    </p>

    <p>
      Abbott may share your information within
      <a class="font-blue" href="https://www.abbott.com/product-list.html">the Abbott family of companies</a>.
    </p>

    <p>
      We do not sell your personal information. However, we share personal information with our vendors, contractors,
      business and service partners, or other third parties (collectively, “Service Providers”). Examples of Service
      Providers include analysis firms, advertisers, payment processing companies, customer service and support
      providers, email, IT services and SMS vendors, web hosting and development companies and fulfillment companies.
      Service Providers also include our co-promotion partners for products that we jointly develop and/or market with
      other companies.
    </p>

    <p>
      Some Service Providers may collect personal data on behalf of Abbott. These third parties may be provided with
      access to personal data needed to perform their functions. In addition, Abbott’s practice is to require its
      Service Providers to keep your personal information confidential and to use personal information only to perform
      functions for Abbott.
    </p>

    <p>
      If Abbott sells all or part of one of its product lines or divisions, your information may be transferred to the
      buyer. Abbott reserves the right to disclose your information to respond to authorized information requests from
      government authorities, to respond to valid judicial requests, to address national security situations, to provide
      security and investigate potential fraud, or when otherwise required by applicable law.
    </p>

    <p>
      Abbott may also disclose your personal information as required by law to any competent law enforcement body,
      regulatory or government agency, court or other third party where we believe the disclosure is necessary or
      appropriate to comply with a regulatory requirement, judicial proceeding, court order, government request or legal
      process served on us, or to protect the safety, rights, or property of our customers, the public, Abbott or
      others, and to exercise, establish or defend Abbott’s legal rights.
    </p>

    <p>
      Abbott may also disclose aggregate or de-identified data that is not personally identifiable to third parties for
      any purpose.
    </p>

    <p><span class="subheading">Cookies and Other Tools</span></p>

    <p>
      Abbott and its Service Providers collect information about you by using cookies, tracking pixels, web beacons and
      other technologies (collectively, “Tools”). We use this information to better understand, customize and improve
      user experience with our websites, services and offerings as well as to manage our advertising. This information
      can make your use of our Abbott Services easier and more meaningful by allowing Abbott and our Service Providers
      to provide better service, customize sites based on consumer preferences, compile statistics, provide you with
      more relevant advertisements based on your interests, analyze trends and otherwise administer and improve our
      Abbott Services. If we have collected your personal information, we may associate this personal data with
      information gathered through the Tools.
    </p>

    <p>
      Your web browser can be set to allow you to control whether you will accept cookies, reject cookies, or to notify
      you each time a cookie is sent to your browser. If your browser is set to reject cookies, websites that are
      cookie-enabled will not recognize you when you return to the website, and some website functionality may be lost.
      The Help section of your browser may tell you how to prevent your browser from accepting cookies. To find out more
      about cookies, visit
      <a class="font-blue" href="http://www.aboutcookies.org/" target="_blank">www.aboutcookies.org</a>.
    </p>

    <p>
      You can also control Flash cookies which we may use on certain Abbott websites and apps from time to time by
      clicking
      <a class="font-blue"
        href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
        target="_blank">here</a>. Why? Because Flash cookies cannot be controlled through your browser settings. Choices
      you make are both browser and device-specific.
    </p>

    <p class="subheading">
      HERE ARE SOME OF THE TYPES OF INFORMATION COLLECTED WHEN VISITING WEBSITES:
    </p>

    <p>
      <b><i>Log data:</i></b> When you use Abbott websites and apps, our servers automatically record information (“log
      data”), including information that your browser sends whenever you visit an website or app or your mobile app
      sends when you are using it. This log data may include your Internet Protocol address, the address of the webpages
      you visited that had Abbott features, browser type and settings, the date and time of your request, how you used
      websites and cookie data.
    </p>

    <p>
      <b><i>Cookie data:</i></b> Depending on how you are accessing Abbott’s online Services, we and our Service
      Providers may use &quot;cookies&quot; (a small text file sent by your computer each time you visit our sites,
      unique to your Abbott account or your browser) or similar technologies, to record log data. When we use cookies,
      we may use &quot;session&quot; cookies (that last until you close your browser) or &quot;persistent&quot; cookies
      (that last until you and/or your browser delete them). For example, we may use cookies to remember your personal
      preferences, such as sections of the website that you visit frequently, your user ID, or other settings so you do
      not have to set them up every time you visit, and we may use cookies to serve you relevant advertising tailored to
      your interests. Some of the cookies we use are associated with your Abbott account (including personal information
      about you, such as the email address you gave us), and other cookies are not.
    </p>

    <p>
      We may use Google Analytics, which uses cookies and similar technologies to collect and analyze information about
      use of the Abbott Services and report on activities and trends. This service may also collect information
      regarding the use of other websites, apps and online resources. You can learn about Google’s practices by going to
      <a class="font-blue" href="https://policies.google.com/privacy/google-partners"
        target="_blank">https://policies.google.com/privacy/google-partners</a>, and opt-out of them by downloading the
      Google Analytics opt-out browser add-on, available at
      <a class="font-blue" href="https://tools.google.com/dlpage/gaoptout"
        target="_blank">https://tools.google.com/dlpage/gaoptout</a>.
    </p>

    <p>
      <b><i>Social media plugins and data.</i></b> In addition, our websites and apps may use social media plugins
      (e.g., the Facebook &quot;Like&quot; button, '&quot;Share to Twitter&quot;' button) to enable you to easily share
      information with others. When you visit any of our online Abbott Services, the operator of the social plugin can
      place a cookie on your computer, enabling that operator to recognize individuals who have previously visited our
      websites. If you are logged into the social media website (e.g., Facebook, Twitter, Google+) while browsing on our
      Abbott Services, the social plugins allow that social media website to receive information that you have visited
      our Abbott Services. The social plugins also allow the social media website to share information about your
      activities on Abbott with other users of their social media website. Abbott does not control any of the content
      from the social plugins. For more information about social plugins from other social media websites you should
      refer to those sites' privacy and data sharing statements.
    </p>

    <p>
      If you choose to link your social media or Google account to Abbott, this allows us to obtain information from
      those accounts (such as your friends or contacts). The information we obtain from these services often depends on
      your settings or the applicable provider's privacy policies, so please check these policies before granting us
      access. You agree that we are not responsible for any acts or omissions by these social media service providers.
      If you give us permission to access this information, we will use it for the purposes described in this Privacy
      Policy.
    </p>

    <p>
      <b><i>Device information.</i></b> In addition to log data, we may also collect information about the device you
      are using to access our Abbott Services, including what type of device it is, what operating system you are using,
      device settings, unique device identifiers and crash data. Whether we collect some or all of this information
      often depends on what type of device you are using and its settings. For example, different types of information
      are available depending on whether you are using a Mac or a PC, or an iPhone or an Android phone. To learn more
      about what information your device may make available to us, please also check the policies of your device
      manufacturer or software provider.
    </p>

    <p>
      <b><i>Interest Based Advertising.</i></b> Abbott hopes to deliver to you advertising for products and services you
      need or want. Abbott, its vendors and partners, use common tracking tools, such as cookies, pixels, web beacons,
      and similar technologies, to automatically collect information about users for purposes of enhancing their online
      experience and delivering more meaningful advertisements. Like many companies, Abbott may also utilize third-party
      advertising vendors and other partners to collect this information.
    </p>

    <p>
      Like most companies, we place advertisements where we think they will be most relevant to customers. One way we do
      so is by allowing network advertising companies with whom we work to place their own Tools when an individual
      visits Abbott websites and apps. This enables the network advertising company to recognize individuals who have
      previously visited Abbott websites and apps and to serve you ads based on your web browsing activity, purchases,
      interests and/or other information—a practice commonly referred to as interest-based advertising (&quot;IBA&quot;)
      or online behavioral advertising (&quot;OBA&quot;).
    </p>

    <p>
      These advertising companies may also use these types of technologies to recognize you across the devices you use,
      such as a mobile phone or a laptop, and serve you ads on websites you visit and applications you use.
    </p>

    <p>
      <b><i>Retargeting.</i></b> One way in which we deliver more meaningful advertisements is through a common form of
      online advertising known as &quot;retargeting&quot; or &quot;remarketing.&quot; Retargeting works by serving ads
      on one site based on an individual’s online activities on a different, unrelated site. For example, if you look at
      Similac<sup>®</sup> products on an Abbott website, you may later see an advertisement for Similac<sup>®</sup>
      products when you visit third-party sites. To do this, Abbott or its advertising vendors may use a device ID,
      cookie, pixel, web beacon or similar technology placed by Abbott or our third-party vendor when you visit Abbott
      websites or apps or third-party websites or apps. The placing of these cookies or other technologies on your
      device may enable you to be identified across multiple websites. You should consult the privacy policies of our
      vendors and other websites that you visit to understand how they collect and use your data.
    </p>

    <p><b><i>Opting-Out of IBA, OBA and Retargeting Activities</i></b></p>
    <p>
      As a participant of the Digital Advertising Alliance (DAA), we adhere to DAA’s Interest-Based Advertising
      principles by providing you enhanced notice, transparency and control of our digital marketing practices as stated
      at http://www.aboutads.info/principles/. Click
      <a class="font-blue" href="http://optout.aboutads.info/?c=2&amp;lang=EN" target="_blank">here</a>
      to opt-out of having your online behavior collected for advertising purposes and receiving behaviorally targeted
      advertisements from our advertising partners. Choices you make are both browser and device-specific. Also, certain
      versions of the iOS operating system permit you to
      <a class="font-blue" href="https://support.apple.com/en-us/HT202074" target="_blank">&quot;Limit Ad
        Tracking&quot;</a>
      in the system settings, and certain versions of the Android operating system allow you to
      <a class="font-blue" href="https://support.google.com/ads/answer/2662922?hl=en" target="_Blank">&quot;Opt-out of
        Interest-Based Ads&quot;</a>
      in the system settings. Options you select are browser and device specific.
    </p>

    <p>
      <b><i>Abbott Do Not Track Policy.</i></b> Some browsers have Do Not Track (&quot;DNT&quot;) features that allow
      you to tell a website not to track you. These features are not all uniform, and some browsers may not be set up to
      honor such requests. Abbott honors the AdChoice DNT signals. Options you select are browser and device specific.
      Please be aware that limiting the ability of websites to set cookies, however, may worsen your overall user
      experience, and in some cases the sites will not work properly without cookies, local storage and similar
      technologies.
    </p>


    <p>
      What Choices Do I Have About How Abbott Collects and Uses Information about Me?<br />
      Certain Abbott Services may ask whether you wish to opt-out or opt into our contact lists for offers, promotions
      and additional services that may be of interest to you.</p>


    <p><span class="subheading">Marketing</span></p>

    <p>
      If you no longer wish to receive promotional marketing materials from us on a going-forward basis, you may opt-out
      of receiving such materials. You may unsubscribe from receiving marketing or other commercial emails from Abbott
      by following the instructions included in the email. We will endeavor to comply with your request as soon as
      reasonably practicable.
    </p>

    <p>
      However, even if you opt out of receiving such communications, we retain the right to send you non-marketing
      communications (such as information about changes to our Privacy Policy or Terms of Use).
    </p>

    <p><span class="subheading">Your Privacy Choices</span></p>

    <p>
      You may have certain rights and choices regarding our processing of your personal data. Depending on your
      jurisdiction, applicable law may entitle you to additional consumer rights, including the right (with certain
      limitations and exceptions) to:
    </p>

    <ul>
      <li>
        Know the categories and/or specific pieces of personal information collected about you, including whether your
        personal information is sold or disclosed, and with whom your personal information was shared
      </li>
      <li>Access a copy of the personal information we retain about you</li>
      <li>Request deletion of your personal information
      </li>
    </ul>

    <p>
      We reserve the right to verify your identity in connection with any requests regarding personal information to
      help ensure that we provide the information we maintain to the individuals to whom it pertains, and allow only
      those individuals or their authorized representatives to exercise rights with respect to that information. If you
      are an authorized agent making a request on behalf of a consumer, we may require and request additional
      information to verify that you are authorized to make that request.
    </p>

    <p>
      We reserve the right to deny your request if we cannot verify your identify. Where we deny your request in whole
      or in part, we will endeavor to inform you of the denial and provide an explanation of our actions and the reasons
      for the denial.
    </p>

    <p>
      We will not restrict or deny you access to our Abbott Services because of choices and requests you make in
      connection with your personal information. Please note, certain choices may affect our ability to deliver the
      Abbott Services. For example, if you sign up to receive marketing communications by email, then ask Abbott to
      delete all of your information, we will be unable to send you marketing communications.
    </p>

    <p>
      You may exercise any of your rights in relation to your personal information by contacting us using the details
      provided under the
      <a class="font-blue" href="https://www.abbott.com/privacy-policy.html#contact" target="_blank">&quot;How Can I
        Contact Abbott&quot;</a>
      section below.
    </p>

    <p><span class="subheading">How does Abbott secure information?</span></p>

    <p>
      It is Abbott’s practice to take steps to secure our Abbott Services; however, the confidentiality of information
      transmitted over the Internet cannot be guaranteed. We urge you to exercise caution when transmitting personal
      information over the Internet, especially personal information related to your health. Abbott cannot guarantee
      that unauthorized third parties will not gain access to your information; therefore, when submitting personal
      information to Abbott websites or apps, you must weigh both the benefits and the risks. Abbott websites or apps
      may link to third-party websites that are not controlled by Abbott or subject to an Abbott privacy policy. You
      should check the privacy policies of such third-party websites before submitting personal information.
    </p>

    <p><span class="subheading">This websites is not for children</span></p>
    <p>

      Abbott does not knowingly collect or use any information from children (we define &quot;children&quot; as minors
      younger than 13). We do not knowingly allow children to order our products, communicate with us, or use any of our
      online services. If you are a parent and become aware that your child has provided us with information, please
      contact us using one of the methods specified below and we will work with you to address this issue.
    </p>

    <p><span class="subheading">Third party sites</span></p>
    <p>
      We may provide links to websites and other third-party content that is not owned or operated by Abbott. The
      websites and third-party content to which we link may have separate privacy notices or policies. Abbott is not
      responsible for the privacy practices of any entity that it does not own or control.
    </p>

    <p>
      If you provide any personal through a third-party site, your interaction and your personal information will be
      collected by, and controlled by the privacy policy of, that third party site. We recommend that you familiarize
      yourself with the privacy policies and practices of any such third parties, which are not governed by this privacy
      policy.
    </p>

    <p><span class="subheading">Cross Border Transfer</span></p>

    <p>
      Any information you provide to Abbott may be stored and processed, transferred between and accessed from the
      United States, where Abbott is headquartered, and other countries which may not guarantee the same level of
      protection of personal data as the one in which you reside. However, Abbott will handle your personal data in
      accordance with this privacy policy regardless of where your personal data is stored/accessed.

    </p>

    <p><span class="subheading">How Can I Contact Abbott?</span></p>

    <p>
      If you have questions about your information, please contact us by clicking on the &quot;Contact Us&quot; link on
      the website you are visiting or by e-mailing us at
      privacy@abbott.com. Alternatively, you may send a letter
      to the following address:
    </p>

    <p><span class="subheading">For Kuwait and the United Arab Emirates :</span></p>


    <p>
      Abbott Laboratories GmbH<br />
      Attention: Office of Ethics and Compliance<br />
      Building 5W, Block A<br /><br />
      Office 801, Dubai Airport Freezone<br />
      Dubai, P.O. Box 32002<br /><br />
      United Arab Emirates<br /><br />


      For Saudi Arabia:<br /><br />
      Abbott Saudi Arabia Trading Company LLC<br />
      Attention: Office of Ethics and Compliance<br />
      P.O. Box. 16082<br /><br />
      Riyadh, 11464<br /><br />
      Saudi Arabia

    </p>

    <p>
      In all communications to Abbott, please include the email address used for registration (if applicable), the
      website address or the specific Abbott program to which you provided personal information (e.g. Abbott.com,
      Similac.com, etc.) and a detailed explanation of your request. We will do our best to respond to all reasonable
      requests in a timely manner.
    </p>

    
    <p>
      How will I know whether Abbott has updated this policy?<br />
      Abbott reserves the right to amend this Privacy Policy at any time. If Abbott changes its privacy practices, a new
      Privacy Policy will reflect those changes and the effective date of the revised Privacy Policy will be set forth
      in this paragraph. This Privacy Policy was last updated on November 1, 2020 and is effective as of that date.
    </p>


    <!-- </li> -->
  </ul>
  <div class="arrow-up">
    <a (click)="onArrowUp()">
      <i class="fa fa-arrow-up"></i>
    </a>
  </div>
</section>
<!-- <app-bottom-menu></app-bottom-menu> -->
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>